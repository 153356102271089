import axios from "axios";
import { getAuth } from "firebase/auth";
import Constants from "./Constants";

interface IExerciseService {
    getAllExercises: (uid?: string) => Promise<any>;
}

class ExerciseService implements IExerciseService {
    getAllExercises = async (uid?: string) => {    
        const auth = getAuth();
        let idToken = await auth.currentUser?.getIdToken(true);

        let url = `${Constants.apiBaseUrl}/exercise/all`;
        try {
            if (!idToken) throw new Error("invalid token");
            let res = await axios({
                url, 
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${idToken}`
                }
            });

            if (res.data) return { success: true,  ...res.data };
            else return { success: false, exercises: [] };
        } catch (err) {
            return { success: false };
        }
    };
}

export default new ExerciseService() as IExerciseService
import axios from "axios";
import { getAuth } from "firebase/auth";
import { CompressedServingInfo, LogDay, LogDayEntry, Meal, MealEntry } from "../types/LogTypes";
import Constants from "./Constants";
import _ from 'lodash';

type LogDayResponse = {
    success: boolean,
    logDays?: Array<LogDay>
}

interface ILogDayService {
    // getFullName: (firstName: string, lastName: string) => string;
    getLogDaysByDateRange: (startDate: string, endDate: string, uid: string) => Promise<LogDayResponse>;
    getEmptyLogDay: (date: string) => LogDayEntry;
    roundNumbers: (serving: any, numServings: any) => any;
    roundNumber: (macro: any) => any;
    getNutritionTotalsForLogDayMeals: (meals: Array<Meal>, limitMeal?: boolean) => CompressedServingInfo;
    averageOutLogDayTotals: (dayMacroTotalsArray:Array<CompressedServingInfo>) => CompressedServingInfo;
}

class LogDayService implements ILogDayService {
    getLogDaysByDateRange = async(startDate: string, endDate: string, uid: string) => {
        const auth = getAuth();
        let idToken = await auth.currentUser?.getIdToken(true);
    
        try {
            if (!idToken) throw new Error("invalid token");

            let res = await axios({
                url: `${Constants.apiBaseUrl}/logDay/startDate/${startDate}/endDate/${endDate}?uid=${uid}`, 
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${idToken}`
                }
            });
            return { success: true, logDays: res.data };
        } catch (err) {
            //could alert there was error or that they are in offline mode
            return { success: false };
        }
    }

    getEmptyLogDay = (date: string):LogDayEntry => { //note: this code was copied from galaxy_app => MealLoggerService.getEmptyLogDay()
        return {
            date,
            meals: [{ name: 'Breakfast', entries: [] }, { name: 'Lunch', entries: [] }, { name: 'Dinner', entries: [] }, { name: 'Snacks', entries: [] }],
            exercises: [],
            steps: 0,
            water: 0,
            weight: 0
        }
    }

    roundNumber = (macro: any) => {
        let exceptionHandled = false;
        let data = macro ? Number(macro).toFixed(1).replace(/[.,]0$/, "") : macro;
        try { data = parseFloat(data); } catch { exceptionHandled = true; }
    
        if (!exceptionHandled) {
            if (data < 1 && data > 0) {
                let dataAsStr = data.toString();
                let parts = dataAsStr.split('.');
                if (parts && parts[1]) {
                    dataAsStr = `.${parts[1]}`;
                    data = dataAsStr;
                }
            } else {
                //data = Math.round(data);
            }
        }
    
        return data;
    }

    roundNumbers = (serving: any, numServings: any = false) => {
        if (!serving) return;

        let calculatedServing = JSON.parse(JSON.stringify(serving));

        //note: we need to store both
        const servingPropsToIgnoreForComputations = ['serving_id', 'sId', 'serving_url', 'sUrl', 'metric_serving_unit', 'msUnit', 'measurement_description', 'mDesc', 'serving_description', 'sDesc'];

        for (var propertyName in serving) {
            if (servingPropsToIgnoreForComputations.includes(propertyName)) continue;

            if (serving[propertyName]) {
                calculatedServing[propertyName] = (serving[propertyName] > 0) ? this.roundNumber(serving[propertyName]) : 0;
                if ((calculatedServing[propertyName] > 0)) {
                    calculatedServing[propertyName] = numServings ? this.roundNumber(serving[propertyName] * numServings) : this.roundNumber(serving[propertyName]);
                }
            }
        }

        return calculatedServing;
    }

    getNutritionTotalsForLogDayMeals = (meals: Array<Meal>, limitMeal: any = false):CompressedServingInfo => {
        // @ts-ignore
        let macroTotals:CompressedServingInfo = {
            // pro: 0,
            // carb: 0,
            // fat: 0,
            // cals: 0
        }
    
        _.forEach(meals, (meal) => {
            if (!limitMeal || limitMeal === meal.name) {
                _.forEach(meal.entries, (entry:MealEntry) => {
                    let servInfo:CompressedServingInfo = entry.loggedCalculatedServing;

                    this.attemptToSetNutritionItem(servInfo?.cals, 'cals', macroTotals);
                    this.attemptToSetNutritionItem(servInfo?.pro, 'pro', macroTotals);
                    this.attemptToSetNutritionItem(servInfo?.carb, 'carb', macroTotals);
                    this.attemptToSetNutritionItem(servInfo?.fat, 'fat', macroTotals);
                    this.attemptToSetNutritionItem(servInfo?.fib, 'fib', macroTotals);
                    this.attemptToSetNutritionItem(servInfo?.sug, 'sug', macroTotals);
                    this.attemptToSetNutritionItem(servInfo?.as, 'as', macroTotals);
                    this.attemptToSetNutritionItem(servInfo?.sFat, 'sFat', macroTotals);
                    this.attemptToSetNutritionItem(servInfo?.polyFat, 'polyFat', macroTotals);
                    this.attemptToSetNutritionItem(servInfo?.mFat, 'mFat', macroTotals);
                    this.attemptToSetNutritionItem(servInfo?.tFat, 'tFat', macroTotals);
                    this.attemptToSetNutritionItem(servInfo?.chol, 'chol', macroTotals);
                    this.attemptToSetNutritionItem(servInfo?.sod, 'sod', macroTotals);
                    this.attemptToSetNutritionItem(servInfo?.pot, 'pot', macroTotals);
                    this.attemptToSetNutritionItem(servInfo?.vD, 'vD', macroTotals);
                    this.attemptToSetNutritionItem(servInfo?.vA, 'vA', macroTotals);
                    this.attemptToSetNutritionItem(servInfo?.vC, 'vC', macroTotals);
                    this.attemptToSetNutritionItem(servInfo?.calc, 'calc', macroTotals);
                    this.attemptToSetNutritionItem(servInfo?.ir, 'ir', macroTotals);
                });
                
                this.roundNumbers(macroTotals);
            }
        });
    
        return macroTotals;
    }

    averageOutLogDayTotals = (dayMacroTotalsArray:Array<CompressedServingInfo>):CompressedServingInfo => {
        let nutritionAveragesSummary:any = undefined;
        if (dayMacroTotalsArray.length > 0) { //note: we don't want zero days hurting the averages
            _.forEach(dayMacroTotalsArray, (dayTotals:any) => {
                if (!nutritionAveragesSummary) {
                    nutritionAveragesSummary = dayTotals;
                } else {
                    for (var prop in dayTotals) {
                        if (Object.prototype.hasOwnProperty.call(dayTotals, prop)) {
                            if (!nutritionAveragesSummary[prop]) nutritionAveragesSummary[prop] = 0;
                            nutritionAveragesSummary[prop] += dayTotals[prop];
                        }
                    }
                }
            });
        }

        if (dayMacroTotalsArray) {
            for (var prop in nutritionAveragesSummary) {
                if (Object.prototype.hasOwnProperty.call(nutritionAveragesSummary, prop)) {
                    nutritionAveragesSummary[prop] = (nutritionAveragesSummary[prop] / dayMacroTotalsArray.length);
                    if (nutritionAveragesSummary[prop] > 0) {
                        nutritionAveragesSummary[prop] = Math.round(nutritionAveragesSummary[prop]);
                    }
                }
            }
        }

        return nutritionAveragesSummary;
    }

    private attemptToSetNutritionItem = (item:any, propName: string, objectToSet: any) => {
        if (item || item == 0) {
            if (!objectToSet[propName]) objectToSet[propName] = 0;
            objectToSet[propName] += Math.round(parseFloat(item));
        }
    }
}

export default new LogDayService() as ILogDayService
class Constants
{
    private static _instance: Constants;

    //public apiBaseUrl: string = `http://localhost:3000`;
    public apiBaseUrl: string = `https://api.macrocoach.io`;
    public appVersion: string = `0.0.1`;

    private constructor()
    {
        //...
    }

    public static get Instance()
    {
        // Do you need arguments? Make it a regular static method instead.
        return this._instance || (this._instance = new this());
    }
}

export default Constants.Instance;
import React, { PureComponent } from 'react';
import { XAxis, ResponsiveContainer, AreaChart, YAxis, Tooltip, Area } from 'recharts';
import CommonDataService from '../services/CommonDataService';

export type BasicAreaChartData = {
    xAxisLabel: string;
    value: number;
    valueSuffix: string;
    //logDay: LogDay;
}

export type BasicAreaChartProps = {
    width: number | string;
    height: number | string;
    chartData: Array<BasicAreaChartData>;
};

export class BasicAreaChart extends PureComponent<BasicAreaChartProps> {

    handleBarClick = (data: any, index: number) => {
        //this.props.onBarChartClicked(data);
    }

    CustomTooltip = ({ active, payload, label }:any) => {
        if (active && payload && payload.length) {
            return (
                <div style={{backgroundColor: 'rgba(220,220,220, 0.8)', padding: 5, borderRadius: 8}}>
                    <div>{label}:</div>
                    <b>{`${payload[0].value} ${payload[0].payload.valueSuffix}`}</b>
                    {/* <p className="intro">something herer...</p>
                    <p className="desc">Anything goes here.</p> */}
                </div>
            );
        }

        return null;
    };

    render() {
        return (
            <div style={{ width: this.props.width, height: this.props.height }}>
                <ResponsiveContainer width="100%" height="100%">
                    <AreaChart
                        width={this.props.width as number}
                        height={this.props.height as number}
                        data={this.props.chartData}
                        margin={{
                            top: 10,
                            right: 30,
                            left: 0,
                            bottom: 0,
                        }}>
                        <XAxis dataKey="xAxisLabel" />
                        <YAxis />
                        <Tooltip content={<this.CustomTooltip />} />
                        <Area type="monotone" dataKey="value" stroke={CommonDataService.getAppColors().darkTeal} fill={CommonDataService.getAppColors().darkTeal} />
                    </AreaChart>
                </ResponsiveContainer>
            </div>
        );
    }
}

import { Routes, Route, BrowserRouter, Outlet, useLocation } from "react-router-dom";
import Content from './pages/Content';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfService from './pages/TermsOfService';
import Support from './pages/Support';
import Login from './pages/Login';
import Users from './pages/Users';
import ErrorPage from "./pages/ErrorPage";
import ProtectedRoute from './components/ProtectedRoute';
import { auth } from './firebase';
import { useAuthState } from 'react-firebase-hooks/auth';

import './App.scss';
import Footer from "./components/Footer";
import { useEffect, useReducer, useState } from "react";
import UserLog from "./pages/UserLog";
import Messages from "./pages/Messages";
import ProgramSignup from "./pages/ProgramSignup";
import WeeklyCheckins from "./pages/WeeklyCheckins";
import NavigationBar from "./components/NavigationBar";
import logo from './img/logo.svg';
import { NotificationContext } from "./hooks/ContextHooks";
import { CoachNotification } from "./types/CoreTypes";
import NotificationService from "./services/NotificationService";
import Applications from "./pages/Applications";

function BasicLayout() {
    const [footerStyle, setFooterStyle]: any = useState<any>({});
    const location = useLocation();

    useEffect(() => {
        //todo: any other fullscreen pages will need to apply this fixed footer at bottom
        if (location.pathname === '/login') setFooterStyle({position: 'fixed', bottom: 0, right: 0, left: 0});
        else setFooterStyle({});
    }, [location.pathname]);


    return (
        <>
            {/* <NavBar /> */}
            <Outlet />
            <div style={footerStyle}>
                <Footer />
            </div>
        </>
    )
}

function App() {
    const [user] = useAuthState(auth);
    const reducer = (state: CoachNotification, pair: CoachNotification) => ({ ...state, ...pair });

    const initialState: CoachNotification = {
        unreadUserCheckins: []
    }

    const [state, update] = useReducer(reducer, initialState)

    useEffect(() => {
        if (user) {
            loadAppNotifications();
        }
    }, [user]);

    const loadAppNotifications = async () => {
        let res = await NotificationService.getCoachNotifications();

        if (res.success) {
            update({...res.notifications});
        }
    }

    const RenderRoutes = () => {
        return (
            <Routes>
                <Route path="/" element={<BasicLayout />}>
                    <Route path="*" element={<ErrorPage />} />
                    <Route path="/error" element={<ErrorPage />} />
                    <Route index path="/" element={<Content />} />
                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                    <Route path="/terms-service" element={<TermsOfService />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/support" element={<Support />} />
                    {/* <Route path="/programSignup" element={<ProgramSignup />} /> */}
                    <Route path="/programSignup/:pg" element={<ProgramSignup />} />
                </Route>
                
                <Route element={<ProtectedRoute />}>
                    <Route path="/messages/:id?" element={<Messages />} />
                    <Route path="/users" element={<Users />} />
                    <Route path="/userLog" element={<UserLog />} />
                    <Route path="/weeklyCheckins" element={<WeeklyCheckins />} />
                    <Route path="/applications" element={<Applications />} />
                    {/* Handle other routes */}
                </Route>
            </Routes>
        )
    }

    return (
        <BrowserRouter>
            <NotificationContext.Provider value={{ state, update }}>
            <div className="App">
                <header className="App-header">
                    <img className="app-header-logo" src={logo} alt="Macro Coach Logo" />
                    <h1 className="visually-hidden">Macro Coach</h1>
                    {user && <NavigationBar />}
                </header>
            
                <RenderRoutes />
                {/* {!user && <RouterProvider router={unauthenticatedrouter} />}
                {user && <RouterProvider router={authenticatedRouter} />} */}
            </div>
            </NotificationContext.Provider>
        </BrowserRouter>
    );
}

export default App;

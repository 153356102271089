import { useEffect, useState } from "react"
import logo from '../img/logo.svg';
import { Button, Checkbox, Form, Input, Modal, message } from "antd"
import { auth } from '../firebase';
import { signOut } from 'firebase/auth';
import {  signInWithEmailAndPassword   } from 'firebase/auth';
import { Link, useNavigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Navigate } from 'react-router-dom';
import UserService from "../services/UserService";
import CommonDataService from "../services/CommonDataService";
import ExerciseService from "../services/ExerciseService";

export default function Login() {
    const [email, setEmail]: any = useState('');
    const [password, setPassword]: any = useState('');
    const [user, loading] = useAuthState(auth);
    const [isUnsupportedLoginModalOpen, setIsUnsupportedLoginModalOpen] = useState<boolean>(false);
    const [messageApi, contextHolder] = message.useMessage();
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    if (loading) return null; 

    if (user && CommonDataService.getCurrentUser()?.isAdmin) {
        return <Navigate to="/users" />;
    }

    const onSubmit = () => {
        signInWithEmailAndPassword(auth, email, password)
        .then(async(userCredential) => {
            // Signed in

            let result = await UserService.getUserFromApi();

            if (result.success) {
                if (result.user && !result.user.isAdmin) { //note: currently we only have support for admin users
                    CommonDataService.removeCurrentUser();
                    signOut(auth);
                    setIsUnsupportedLoginModalOpen(true);
                    return;
                } else if (result.isPartialUser) {
                    navigate("/programSignup/1");
                    return;
                } else if (result.user && result.user.isAdmin) {
                    CommonDataService.setCurrentUser(result.user);
                }
            }

            let exerciseResult = await ExerciseService.getAllExercises();

            if (exerciseResult?.exercises) {
                CommonDataService.setAllExercises(exerciseResult?.exercises);
            }

            navigate("/users")
        })
        .catch((error) => {
            if (error.code === 'auth/user-not-found') {
                messageApi.open({
                    type: 'error',
                    content: 'Email not found. Invalid email/password combination'
                });
                return;
            }
            messageApi.open({
                type: 'error',
                content: 'Sorry, an unkown error occurred'
            });
        });
    }

    return (
        <>
            {contextHolder}
            <div className="login">
                <img className="app-logo" style={{ padding: 20 }} width={150} height={150} src={logo} alt="Macro Coach Logo" />

                {/* <div className="close"><Link to={"/"}>Close</Link></div> */}
                <Form
                    name="basic"
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 17 }}
                    style={{ maxWidth: 600 }}
                    initialValues={{ remember: true }}
                    onFinish={onSubmit}
                    // onFinishFailed={onSubmitFailed}
                    autoComplete="off"
                >
                    <Form.Item
                        label="Email"
                        name="email"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter your email address',
                            },
                        ]}>
                        <Input 
                            value={email}
                            onChange={(e) => setEmail(e.target.value)} />
                    </Form.Item>

                    <Form.Item
                        label="Password"
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter your password',
                            },
                        ]}>
                        <Input.Password
                            value={password}
                            onChange={(e) => setPassword(e.target.value)} />
                    </Form.Item>

                    <div className="form-controls">
                        <Form.Item
                            className="remember"
                            name="remember"
                            valuePropName="checked"
                            >
                            <Checkbox>Remember me</Checkbox>
                        </Form.Item>

                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                                Submit
                            </Button>
                        </Form.Item>
                    </div>
                </Form>
                <Modal
                    open={isUnsupportedLoginModalOpen}
                    onCancel={() => setIsUnsupportedLoginModalOpen(false)}
                    title="Admin only"
                    footer={[
                        <Button key="submit" type="primary" onClick={() => {
                            setIsUnsupportedLoginModalOpen(false);
                        }}>
                            Ok
                        </Button>
                    ]}>
                    <div>Sorry, this feature is only available for admin users at the moment. You can use the mobile app which is available on Android and iOS devices. For more information, you can <Link to={"/"}>click here</Link></div>
                </Modal>
            </div>
            {/* <div style={{ position: 'fixed', bottom: 0, right: 0, left: 0 }}>
                <Footer />
            </div> */}
        </>

    )
}
import { User } from "../types/CoreTypes";
import { Exercise } from "../types/LogTypes";

class CommonDataService {
    private static _instance: CommonDataService;

    //note: these defaults are null
    private _mediaSignedUrls: any = {};
    private _mediaFile: any = {};
    private _appColors: any = {
        red: '#EC564F',
        orange: '#F89719',
        green: '#8db40b',
        blue: '#1e8eab',
        darkTeal: '#0c3945',
        limeGreen: '#c0e064'
    };
    private _currentLogDayUser: string | null = null;

    private constructor() {
        //...
    }

    public static get Instance() {
        // Do you need arguments? Make it a regular static method instead.
        return this._instance || (this._instance = new this());
    }

    setMediaSignedUrls(value: any) { this._mediaSignedUrls = value; }
    getMediaSignedUrls(): any {
        if (!this._mediaSignedUrls)
            this._mediaSignedUrls = {};

        return this._mediaSignedUrls;
    }

    setMediaFile(value: any) { this._mediaFile = value; }
    getMediaFile(): any { return this._mediaFile; }

    setCurrentUser(value: any) { localStorage.setItem('currentUser', JSON.stringify(value)); }
    getCurrentUser(): any { 
        //@ts-ignore
        return JSON.parse(localStorage.getItem('currentUser')); 
    }
    removeCurrentUser(): void {
        localStorage.removeItem('currentUser');
    }

    getAppColors() { return this._appColors }

    setCurrentLogDayUser(user:User) {
        if (user) this._currentLogDayUser = JSON.stringify(user);
        else this._currentLogDayUser = null;
    }
    getCurrentLogDayUser():User | null {
        if (this._currentLogDayUser) return JSON.parse(this._currentLogDayUser);
        else return null;
    }

    //note: we only need to stringify and parse if we are going to LocalStorage. Cleanup other places in code
    setAllExercises(exercises: Array<Exercise>) {
        if (exercises) localStorage.setItem('exercises', JSON.stringify(exercises));
    }
    getAllExercises(): Array<Exercise> {
        let jsonString = localStorage.getItem('exercises');
        if (jsonString) return JSON.parse(jsonString as string) as Array<Exercise>;
        else return [];
    }
}

export default CommonDataService.Instance;
import { CloseOutlined } from "@ant-design/icons";
import { Button, Drawer } from "antd";
import { useEffect, useReducer, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { UserNotesContext } from "../../hooks/ContextHooks";
import NoteService from "../../services/NoteService";
import AddNote from "./AddNote";
import { ClientNoteProps } from "./ClientNote";
import ClientNotes from "./ClientNotes";

export type NotesDrawerProps = {
    isOpen: boolean;
    closeDrawer: () => void;
}

export type UserNotesProps = {
    notes?: Array<ClientNoteProps>;
    isAddingNote?: boolean;
}

export default function NotesDrawer ({isOpen, closeDrawer}: NotesDrawerProps) {
    //const [isAddingNote, setIsAddingNote] = useState<boolean>(false);
    const reducer = (state: UserNotesProps, pair: UserNotesProps) => ({ ...state, ...pair });

    let initialState:UserNotesProps = {
        notes: [],
        isAddingNote: false
    }

    const [state, update] = useReducer(reducer, initialState);

    const location = useLocation();
    const { user } = location.state;

    // const onNoteAdded = async (clientNote:ClientNoteProps) => {
    //     clientNote.uid = user.uid;
    //     let response = await NoteService.createUserNote(clientNote);

    //     if (response.success && response.note) {
    //         update({ notes: [response.note, ...state.notes as Array<ClientNoteProps>], isAddingNote: false });
    //     }
    // };

    const onCloseDrawer = () => {
        closeDrawer();
    }

    const loadNotes = async () => {
        let result = await NoteService.getNotesFromApi(user.uid);
        if (result && result.success) update({ notes: result.notes }) //setNotes(result.notes);
    }

    useEffect(() => {
        loadNotes();
    }, []);

    useEffect(() => {
        //ensure we always scroll to top when opening drawer
        if (document.getElementsByClassName('ant-drawer-body')[0]) {
            if (isOpen || !state.isAddingNote) setTimeout(() => { document.getElementsByClassName('ant-drawer-body')[0].scrollTo(0, 0); }, 0);
        }
    }, [isOpen, state]);

    return (
        <div className="notesDrawer">
            <UserNotesContext.Provider value={{ state, update }}>
                <Drawer
                    title="Notes"
                    // getContainer={false}
                    height={'90%'}
                    extra={<>
                        <Button type="primary" style={{marginRight: 20}} onClick={() => {
                            //setIsAddingNote(!isAddingNote);
                            update({ isAddingNote: !state.isAddingNote });
                        }}>{state.isAddingNote ? 'Cancel' : 'Add Note'}</Button>
                        <CloseOutlined onClick={onCloseDrawer} />
                    </>}
                    placement={'bottom'}
                    closable={false}
                    onClose={onCloseDrawer}
                    open={isOpen}
                >
                    {state.isAddingNote && <AddNote />}
                    {(state.notes && state.notes.length > 0) && <ClientNotes />}
                </Drawer>
            </UserNotesContext.Provider>
        </div>
    )
};
import { useContext, useEffect, useMemo, useRef, useState } from "react"
import { Form, Input, Button, Row, Col, Radio, Select, RadioChangeEvent, InputNumber, Flex } from 'antd';
import dayjs from "dayjs";
import { biometricService, HeightFtIn, ValidationResult } from "../../services/BiometricService";
import MaskedInput from 'antd-mask-input';
import { MaskedRange } from "imask";
import { useNavigate } from "react-router-dom";
import { User } from "../../types/CoreTypes";
import { WeightForApi } from "../../types/DTOs";
import { ProgramSignupContext } from "../../hooks/ContextHooks";
import { getAuth } from 'firebase/auth';

export type ProgramSignupProps = {
    user: User,
    weight: WeightForApi
};

// export default function ProgramSignup({ user, weight }: ProgramSignupProps) {
export default function ProgramSignup() {
    const [fname, setFname] = useState<string>();
    const [lname, setLname] = useState<string>();
    const [dob, setDob] = useState<string | null>(null);
    const [weightLbs, setWeightLbs] = useState<string>();
    const [weightKg, setWeightKg] = useState<string>();
    const [weightDisplay, setWeightDisplay] = useState<string>();
    const [heightFt, setHeightFt] = useState<string>();
    const [heightIn, setHeightIn] = useState<string>();
    const [heightCm, setHeightCm] = useState<string>();
    const [gender, setGender] = useState<string>('F');
    const [isStandardHt, setIsStandardHt] = useState<boolean>(true);
    const [isStandardWt, setIsStandardWt] = useState<boolean>(true);
    const [fnameErrMsg, setFnameErrMsg] = useState<string>();
    const [lnameErrMsg, setLnameErrMsg] = useState<string>();
    const [dobErrMsg, setDobErrMsg] = useState<string>();
    const [weightErrMsg, setWeightErrMsg] = useState<string>();
    const [heightErrMsg, setHeightErrMsg] = useState<string>();
    const [hasAttemptedSubmit, setHasAttemptedSubmit] = useState<boolean>(false);

    const navigate = useNavigate();
    const dobInputRef = useRef<any>(null);
    const auth = getAuth();

    const { state, update }:any = useContext(ProgramSignupContext);

    const user = state.user;
    const weight = state.weight;

    useEffect(() => {
        window.scrollTo(0, 0);

        attemptFocusAndBlur();

        const handleBeforeUnload = (event: any) => {
            event.preventDefault();
            // Custom logic to handle the refresh
        };
        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    useEffect(() => {
        if (user) {
            setFname(user.firstName);
            setLname(user.lastName);
            setGender(user.gender);
            if (user.dob && dayjs(user.dob, 'YYYY-MM-DD', true).isValid()) {
                let formattedDate = dayjs(user.dob, 'YYYY-MM-DD').format('MM / DD / YYYY');
                setDob(formattedDate);
            }
            setIsStandardHt(user.heightMetric === biometricService.heightFt);
            if (user.heightCm) setHeightCm(user.heightCm.toString());
            if (user.heightIn) {
                let htResult:HeightFtIn = biometricService.convertInchesToHtFt(user.heightIn);
                setHeightIn(htResult.inches.toString());
                setHeightFt(htResult.ft.toString());
            }
            attemptFocusAndBlur();
        }

        if (weight) {
            setWeightKg(weight.weightKg.toString());
            setWeightLbs(weight.weightLbs.toString());
            if (user.weightMetric === biometricService.weightPounds) {
                setIsStandardWt(true);
                setWeightDisplay(weight.weightLbs.toString());
            } else {
                setIsStandardWt(false);
                setWeightDisplay(weight.weightKg.toString());
            }
        }
    }, [user, weight, update]);

    const attemptFocusAndBlur = () => {
        if (dobInputRef) { //note: hack for odd issue with input mask
            setTimeout(() => { 
                dobInputRef?.current?.focus();
                dobInputRef?.current?.blur();
            }, 0);
        }
    }

    const mask = useMemo<any>(() => {
        return [
            {
                mask: 'MM / DD / YY',
                lazy: false,  // make placeholder always visible

                blocks: {
                    MM: {
                        mask: MaskedRange,
                        from: 1,
                        to: 12,
                        expose: true,
                        placeholderChar: 'M'
                    },
                    DD: {
                        mask: MaskedRange,
                        from: 1,
                        to: 31,
                        expose: true,
                        placeholderChar: 'D'
                    },
                    YY: {
                        mask: MaskedRange,
                        from: (dayjs().year() - 120),
                        to: (dayjs().year() - 18),
                        expose: true,
                        placeholderChar: 'Y'
                    },

                    // VL: {
                    //     mask: MaskedEnum,
                    //     enum: ['TV', 'HD', 'VR'],
                    //     expose: true
                    // }
                }
            }
        ];
    }, []);

    useEffect(() => { //useEffect for handling validation
        if (!hasAttemptedSubmit) return;

        validateForm();
    }, [fname, lname, weightLbs, weightKg, dob, heightCm, hasAttemptedSubmit]);

    const validateForm = async (fromSubmit:boolean=false) => {
        let fnameErr:string | undefined = undefined;
        if (!fname) fnameErr = 'First name is required';
        else if (fname && fname?.length < 2) fnameErr = 'Please enter your first name';
        else if (fname && fname?.length > 36) fnameErr = 'First name is too long';
        else fnameErr = undefined;
        setFnameErrMsg(fnameErr);

        let lnameErr:string | undefined = undefined;
        if (!lname) lnameErr = 'Last name is required';
        else if (lname && lname?.length < 2) lnameErr = 'Please enter your first name';
        else if (lname && lname?.length > 36) lnameErr = 'First name is too long';
        else lnameErr = undefined;
        setLnameErrMsg(lnameErr);

        let weightErr:string | undefined = undefined;
        let wtValidationResult:ValidationResult = biometricService.validateWeight(weightLbs?.toString() as string, weightKg?.toString() as string, biometricService.weightPounds);
        if (!wtValidationResult.isValid) weightErr = wtValidationResult.error;
        else weightErr = undefined;;
        setWeightErrMsg(weightErr);

        let heightErr:string | undefined = undefined;
        let htValidationResult:ValidationResult = biometricService.validateHeightCm(heightCm?.toString() as string);
        if (!htValidationResult.isValid) heightErr = htValidationResult.error;
        else heightErr = undefined;
        setHeightErrMsg(heightErr);

        let dobErr:string | undefined = undefined;
        if (!dayjs(dob, 'MM / DD / YYYY', true).isValid()) dobErr = 'Inalid Date';
        else dobErr = undefined;
        setDobErrMsg(dobErr);
        
        if (fromSubmit) {
            if (!fnameErr && !lnameErr && !weightErr && !heightErr && !dobErr) {

                //todo: await api sync

                let user:User = {
                    dob: dayjs(dob, 'MM / DD / YYYY').format('YYYY-MM-DD'),//(dob as string).trim(),
                    firstName: (fname as string).trim(),
                    lastName: (lname as string).trim(),
                    gender: gender as string,
                    heightCm: Number(heightCm),
                    heightIn: biometricService.convertHtFtToInches(Number(heightFt), Number(heightIn ?? 0)),
                    heightMetric: isStandardHt ? biometricService.heightFt : biometricService.heightCm,
                    weightMetric: isStandardWt ? biometricService.weightPounds : biometricService.weightKg,
                    uid: auth.currentUser?.uid as string,
                    email: auth.currentUser?.email as string
                }

                update({ user });

                let date = dayjs().format('YYYY-MM-DD');
                let weightDto:WeightForApi = {
                    date,
                    weightLbs: parseFloat(weightLbs as string),
                    weightKg: parseFloat(weightKg as string)
                };

                update ({ weight: weightDto });
                //let result = await biometricService.syncWeightWithApi(weightDto);

    
                //navigate("/programSignup/2", { state: { user, weight: weightDto }});
                navigate("/programSignup/2");
            }
        }
    }

    //const onDobChange = (date: Dayjs | null, dateString: string) => { setDob(date ? date?.format('YYYY-MM-DD') : null); }
    const onGenderToggleChange = (e: RadioChangeEvent) => { setGender(e.target.value); }
    const onFtInToggleChange = (e: RadioChangeEvent) => { setIsStandardHt(e.target.value === 'ftin') }
    const onHtFtToggleChange = (value: string) => {
        let htIn = 0;
        if (value) {
            if (heightIn) htIn = Number(heightIn);
            let totalInches = biometricService.convertHtFtToInches(Number(value), htIn);
            let totalCm = biometricService.convertInchesToCm(totalInches);

            setHeightCm(totalCm.toString());
        }
        setHeightFt(value);
    }
    const onHtInToggleChange = (value: string) => {
        let htFt = 0;
        if (value) {
            if (heightFt) htFt = Number(heightFt);
            let totalInches = biometricService.convertHtFtToInches(htFt, Number(value));
            let totalCm = biometricService.convertInchesToCm(totalInches);

            setHeightCm(totalCm.toString());
        }
        setHeightIn(value);
    }
    const onWtLbsKgToggleChange = (e: RadioChangeEvent) => {
        let standardWt = e.target.value === 'lbs';
        setIsStandardWt(standardWt);

        if (standardWt && weightLbs) {
            setWeightDisplay(weightLbs);
        } else if (!standardWt && weightKg) {
            setWeightDisplay(weightKg)
        }
    }

    const onWeightChange = async (value: any) => {
        const re = /^(?:0|[1-9]\d+|)?(?:.?\d{0,2})?$/; //note: limits to 2 decimal place
        let valueCopy = value;

        if (value === '' || re.test(value)) {
            if (isStandardWt) {
                setWeightLbs(valueCopy);
                let kg = biometricService.convertLbsToKg(parseFloat(valueCopy));
                setWeightKg(kg === 0 ? '' : kg.toString());
            } else { //biometricService.weightKg case
                setWeightKg(valueCopy);
                let lbs: number = biometricService.convertKgToLbs(parseFloat(valueCopy));
                setWeightLbs(lbs === 0 ? '' : lbs.toString());
            }
            setWeightDisplay(value);
        }
    }

    const onSubmit = () => {
        setHasAttemptedSubmit(true);
        //todo: actual signup

        validateForm(true);
    }

    return (
        <>
            <div className="biometrics">
                <div>
                    {/* <Card style={{ maxWidth: 600, margin: 'auto' }}> */}
                    <Form
                        layout="vertical"
                        onFinish={onSubmit}
                        autoComplete="off">
                        <Row gutter={48}>
                            <Col span={12}>
                                <Form.Item label="First Name">
                                    <Input 
                                        status={fnameErrMsg ? 'error' : ''}
                                        value={fname} placeholder="enter your first name" onChange={(e) => {
                                            let val = e.target.value;
                                            //fnameRef.current = val;
                                            setFname(val);
                                        }} />
                                    {fnameErrMsg && <div style={{ color: 'red', fontSize: 12 }}>{fnameErrMsg}</div>}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Last Name">
                                    <Input 
                                        value={lname}
                                        status={lnameErrMsg ? 'error' : ''}
                                        placeholder="enter your last name" 
                                        onChange={(e) => setLname(e.target.value)} />
                                    {lnameErrMsg && <div style={{ color: 'red', fontSize: 12 }}>{lnameErrMsg}</div>}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={48}>
                            <Col span={12}>
                                <Form.Item label="Date of Birth">
                                    {/* <DatePicker
                                        style={{ width: '100%' }}
                                        defaultValue={dayjs('01/01/1980', 'MM/DD/YYYY')}
                                        format={'MM/DD/YYYY'}
                                        allowClear={false}
                                        changeOnBlur={true}
                                        onChange={onDobChange} /> */}
                                    <MaskedInput 
                                        ref={dobInputRef}
                                        mask={mask} 
                                        value={dob as string} 
                                        onChange={(e) => setDob(e.target.value)} 
                                        status={dobErrMsg ? 'error' : ''} />
                                    {dobErrMsg && <div style={{ color: 'red', fontSize: 12 }}>{dobErrMsg}</div>}
                                </Form.Item>
                            </Col>
                            <Col span={6} style={{ paddingRight: 0 }}>
                                <Form.Item label="Weight">
                                    <InputNumber
                                        style={{ width: '100%' }}
                                        value={weightDisplay as string}
                                        onChange={onWeightChange}
                                        status={weightErrMsg ? 'error' : ''}/>
                                    {weightErrMsg && <div style={{ color: 'red', fontSize: 12 }}>{weightErrMsg}</div>}
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label=" ">
                                    <Radio.Group value={isStandardWt ? 'lbs' : 'kg'} onChange={onWtLbsKgToggleChange} buttonStyle="solid">
                                        <Radio.Button value="lbs">lbs</Radio.Button>
                                        <Radio.Button value="kg">kg</Radio.Button>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={48}>
                            <Col span={8}>
                                <Form.Item label="Gender">
                                    <Radio.Group value={gender} onChange={onGenderToggleChange} buttonStyle="solid">
                                        <Radio.Button value="F">Female</Radio.Button>
                                        <Radio.Button value="M">Male</Radio.Button>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                            {isStandardHt && <>
                                <Col span={8} style={{ paddingRight: 2 }}>
                                    <Flex justify={'space-between'}>
                                    <Form.Item label="Height" style={{width: '48%'}}>
                                        <Select
                                            //showSearch
                                            placeholder="ft"
                                            optionFilterProp="children"
                                            onChange={onHtFtToggleChange}
                                            value={heightFt}
                                            //defaultValue={'5'}
                                            // onSearch={onSearch}
                                            // filterOption={filterOption}
                                            options={[
                                                { value: '3', label: '3 ft' },
                                                { value: '4', label: '4 ft' },
                                                { value: '5', label: '5 ft' },
                                                { value: '6', label: '6 ft' },
                                                { value: '7', label: '7 ft' },
                                            ]}
                                            status={heightErrMsg ? 'error' : ''}
                                        />
                                    </Form.Item>
                                    <Form.Item label=" " style={{width: '48%'}}>
                                        <Select
                                            //showSearch
                                            placeholder="in"
                                            optionFilterProp="children"
                                            onChange={onHtInToggleChange}
                                            value={heightIn}
                                            //status="error"
                                            //defaultValue="7"
                                            // onSearch={onSearch}
                                            // filterOption={filterOption}
                                            options={[
                                                { value: '0', label: '0 in' },
                                                { value: '1', label: '1 in' },
                                                { value: '2', label: '2 in' },
                                                { value: '3', label: '3 in' },
                                                { value: '4', label: '4 in' },
                                                { value: '5', label: '5 in' },
                                                { value: '6', label: '6 in' },
                                                { value: '7', label: '7 in' },
                                                { value: '8', label: '8 in' },
                                                { value: '9', label: '9 in' },
                                                { value: '10', label: '10 in' },
                                                { value: '11', label: '11 in' }
                                            ]}
                                            status={heightErrMsg ? 'error' : ''}
                                        />
                                    </Form.Item>
                                    </Flex>
                                    {heightErrMsg && <div style={{ color: 'red', fontSize: 12, marginTop: -24 }}>{heightErrMsg}</div>}
                                </Col>
                                {/* <Col span={4} style={{ paddingLeft: 2 }}>
                                    <Form.Item label=" ">
                                        <Select
                                            //showSearch
                                            placeholder="in"
                                            optionFilterProp="children"
                                            onChange={onHtInToggleChange}
                                            value={heightIn}
                                            //status="error"
                                            //defaultValue="7"
                                            // onSearch={onSearch}
                                            // filterOption={filterOption}
                                            options={[
                                                { value: '0', label: '0 in' },
                                                { value: '1', label: '1 in' },
                                                { value: '2', label: '2 in' },
                                                { value: '3', label: '3 in' },
                                                { value: '4', label: '4 in' },
                                                { value: '5', label: '5 in' },
                                                { value: '6', label: '6 in' },
                                                { value: '7', label: '7 in' },
                                                { value: '8', label: '8 in' },
                                                { value: '9', label: '9 in' },
                                                { value: '10', label: '10 in' },
                                                { value: '11', label: '11 in' }
                                            ]}
                                        />
                                    </Form.Item>
                                </Col> */}
                            </>}
                            {!isStandardHt &&
                                <Col span={6} style={{ paddingRight: 0 }}>
                                    <Form.Item label="Height">
                                        <Input
                                            style={{ width: '100%' }}
                                            value={heightCm}
                                            status={heightErrMsg ? 'error' : ''}
                                            onChange={(e) => {
                                                let htCm: string = e.target.value;
                                                if (!htCm) return;

                                                setHeightCm(htCm);
                                                let htInches = biometricService.convertCmToInches(Number(htCm));
                                                let htFtInches: HeightFtIn = biometricService.convertInchesToHtFt(htInches);
                                                setHeightFt(htFtInches.ft.toString());
                                                setHeightIn(htFtInches.inches.toString());
                                            }} />
                                    </Form.Item>
                                    {heightErrMsg && <div style={{ color: 'red', fontSize: 12, marginTop: -24 }}>{heightErrMsg}</div>}
                                </Col>}
                            <Col span={8}>
                                <Form.Item label=" ">
                                    <Radio.Group value={isStandardHt ? 'ftin' : 'cm'} onChange={onFtInToggleChange} buttonStyle="solid">
                                        <Radio.Button value="ftin">ft/in</Radio.Button>
                                        <Radio.Button value="cm">cm</Radio.Button>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Flex justify={'center'}>
                            <Form.Item>
                                <Button type="primary" htmlType="submit">Continue</Button>
                            </Form.Item>
                        </Flex>
                    </Form>
                    {/* </Card> */}
                </div>
            </div>
        </>
    )
}
import { Card } from "antd";
import { auth } from '../firebase';
import { useEffect, useReducer, useState } from "react"
import Biometrics from "../components/ProgramSignup/Biometrics";
import { useNavigate, useParams } from 'react-router-dom';
import WeightLossGoalTargets from "../components/WeightLossGoalTargets";
import { User } from "../types/CoreTypes";
import { WeightForApi } from "../types/DTOs";
import { WeightLossGoalForApi } from "../types/DTOs";
import ReviewWeightLossGoalTargets from "../components/ProgramSignup/ReviewWeightLossGoalTargets";
import { ProgramSignupContext } from "../hooks/ContextHooks";
import { biometricService } from "../services/BiometricService";
import CreateUsernamePassword from "../components/ProgramSignup/CreateUsernamePassword";
import logo from '../img/logo.svg';
import { useAuthState } from "react-firebase-hooks/auth";
import CommonDataService from "../services/CommonDataService";

export type ProgramSignupProps = {
    user: User | null,
    weight: WeightForApi | null,
    weightLossGoal: WeightLossGoalForApi | null
}

export default function ProgramSignup({ navigation }: any) {
    const [authenticatedUser, loading] = useAuthState(auth);
    const reducer = (state: ProgramSignupProps, pair: ProgramSignupProps) => ({ ...state, ...pair });

    const navigate = useNavigate();


    const initialState: ProgramSignupProps = {
        user: {
            dob: '',
            email: '',
            firstName: '',
            gender: 'F',
            heightCm: 0,
            heightIn: 0,
            heightMetric: biometricService.heightFt,
            lastName: '',
            uid: '',
            weightMetric: biometricService.weightPounds,
        },
        weight: null,
        weightLossGoal: null
    }

    const [currStep, setCurrStep] = useState<number>(1);
    const [state, update] = useReducer(reducer, initialState)

    let { pg } = useParams();

    useEffect(() => {
        window.scrollTo(0, 0);
        let pgAsNumber = Number(pg);

        if (pg && pgAsNumber) {
            if (pgAsNumber === 1 || pgAsNumber === 2 || pgAsNumber === 3) {
                if (!loading && !authenticatedUser) {
                    navigate("/login");
                    return
                }
                setCurrStep(pgAsNumber);
            }
            else setCurrStep(0);
        } else {
            setCurrStep(0);
        }
    }, [pg, state, authenticatedUser, loading]);

    useEffect(() => {
        if (CommonDataService.getCurrentUser()) navigate(`/`); //note: users that have already created an account should not be able to access this page

        window.onpopstate = () => {
            navigate(`/programSignup/${pg}`);
        }
    }, []);

    //note: DO NOT REMOVE. THIS PREVENTS USER FROM NAVIGATING BACK
    useEffect(() => {
        let pgAsNumber = Number(pg);
        if (pgAsNumber !== 2) window.onpopstate = () => { navigate(`/programSignup/${pg}`); }
    }, [pg]);

    return (
        <ProgramSignupContext.Provider value={{ state, update }}>
            <div className="programSignup">
                <h1>Welcome!</h1>
                {currStep > 0 && <h2>We need to gather some basic information, but we promise, it will be quick and easy!</h2>}
                {currStep === 0 && <h2>Please create a username and password to register for the Macro Coach app.</h2>}


                <Card title={currStep > 0 ? `Step ${currStep} of 3` : 'Create your account'} style={{ maxWidth: 600, margin: 'auto' }}>
                    <img className="app-logo" width={100} src={logo} alt="Macro Coach Logo" />
                    {(currStep === 0) && <CreateUsernamePassword />}
                    {(currStep === 1) && <Biometrics />}
                    {(currStep === 2) && <WeightLossGoalTargets />}
                    {(currStep === 3) && <ReviewWeightLossGoalTargets />}
                </Card>
            </div>
        </ProgramSignupContext.Provider>
    )
}
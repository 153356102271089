import axios from "axios";
import { getAuth } from "firebase/auth";
import Constants from "./Constants";

interface IMessagesService {
    getAudioMessage: (uid?: string) => Promise<any>;
    deleteMessage: (docId: string) => Promise<any>;
}

class MessagesService implements IMessagesService {
    getAudioMessage = async (uid?: string) => {    
        const auth = getAuth();
        let idToken = await auth.currentUser?.getIdToken(true);

        let url = `${Constants.apiBaseUrl}/messages/mediaFiles`;
        if (uid) url += `/${uid}`;
        try {
            if (!idToken) throw new Error("invalid token");
            let res = await axios({
                url, 
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${idToken}`
                }
            });

            return { success: true, audio: res.data };
        } catch (err) {
            return { success: false };
        }
    };

    deleteMessage = async (docId: string) => {
        if (!docId) {
            return { success: false };
        };

        const auth = getAuth();
        let idToken = await auth.currentUser?.getIdToken(true);

        try {
            if (!idToken) throw new Error("invalid token");
            await axios({
                url: `${Constants.apiBaseUrl}/messages/${docId}`,
                method: 'DELETE',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${idToken}`
                },
            });

            return { success: true };
        } catch (err) {
            console.error('unable to cleanup audio resources');
            return { success: false };
        }
    }
}

export default new MessagesService() as IMessagesService
import { FireFilled } from "@ant-design/icons";
import { Flex } from "antd";
import CommonDataService from "../services/CommonDataService";

export type MacrosFooterProps = {
    cals: any;
    protein: number;
    carbs: number;
    fats: number;
}

export function MacrosFooter ({ cals, protein, carbs, fats }: MacrosFooterProps) {
    return (
        <Flex justify={"end"} wrap="wrap" style={{fontWeight: 'bold', fontSize:12}}>
            <span style={{ marginRight: 5, color: CommonDataService.getAppColors().orange }}>{ cals }<FireFilled /></span>
            <span style={{ marginRight: 5, color: CommonDataService.getAppColors().green }}>{ protein }P</span>
            <span style={{ marginRight: 5, color: CommonDataService.getAppColors().blue }}>{ carbs }C</span>
            <span style={{ color: CommonDataService.getAppColors().red }} >{ fats }F</span>
        </Flex>
    );
}
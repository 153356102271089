import logo from '../img/logo.svg';
import appStore from '../img/app-store.svg';
import googlePlay from '../img/google-play-badge.png';
import appPreview from '../img/app-preview.png';
import appDemo from '../video/mc_app_preview.mp4';
import { useEffect } from "react"

export default function Content(props:any) {

    useEffect(() => {
        
    }, []);

    return (
        <>
            <div className="wrap">
                <div className="text">
                    {/* <img className="app-logo" src={logo} alt="Macro Coach Logo" /> */}

                    <div className="app-store-links">
                        <a href='https://apps.apple.com/app/macro-coach-weight-loss/id6447108107'><img src={appStore} alt="app store" /></a>
                        <a href='https://play.google.com/store/apps/details?id=com.lindseymichael.macrocoach'><img src={googlePlay} alt="google play" /></a>
                    </div>

                    <h3 className='content-header' style={{ fontSize: 30 }}>Make this the LAST time you ever worry about weight</h3>
                    <h3 className='content-header' style={{ fontSize: 30 }}>Weight loss that lasts without sacrificing your favorite foods or social life</h3>
                    <h3 className='content-header' style={{ fontSize: 30 }}>Support from an experienced Registered Dietitian every step of the way</h3>
                    <p>We break down the science of macros into easy to understand, actionable steps. We teach you how to eat your favorite foods, guilt free while you’re losing weight at the same time. Sustainable weight loss is much more than just knowing what to eat. That’s the easy part. The challenging part is knowing how to fit healthy eating into your life while you’re juggling everything else on your to-do list. With Macro Coach, you’re never on your own.</p>
                    <p>Together, we’ll address mindset, motivation, stress, emotional eating, navigating social situations, travel and much more! Your Macro Coach knows the last thing you need is another generic program with strict rules. Your coach knows you want to fuel weight loss without feeling like you’re on a diet. We take the guesswork and frustration out of weight loss with a simple, macro based approach that gives you the time to focus on life’s more important things.</p>
                </div>

                <div className="app-preview">
                    <div className="iphone">
                        <video id="vid" className="screenshot" style={{ borderRadius: 15 }} autoPlay muted loop playsInline>
                            <source src={appDemo}></source>
                            <img src={appPreview} alt="app preview" />
                        </video>
                    </div>
                </div>
            </div>
        </>
    )
}
import { Card } from "antd"
import _ from "lodash";
import moment from "moment";
import { Moment } from "moment";
import { useEffect, useState } from "react";
import LogDayService from "../services/LogDayService";
import UserService from "../services/UserService";
import WeightService from "../services/WeightService";
import { User } from "../types/CoreTypes";
import { BasicAreaChart, BasicAreaChartData } from "./BasicAreaChart"

export type LogDayWeightProps = {
    user: User,
    startDate: Moment,
    endDate: Moment
}

export function LogDayWeight({ user, startDate, endDate }: LogDayWeightProps) {
    const [weightChartData, setWeightChartData] = useState<Array<BasicAreaChartData>>();

    const loadWeights = async (user: User, startDate: Moment, endDate: Moment) => {
        let formattedStartDate: string = startDate.format('YYYY-MM-DD');
        let formattedEndDate: string = endDate.format('YYYY-MM-DD');
        let daysDiff: number = endDate.diff(startDate, 'days');
        let weightResponse: any = await WeightService.getWeightsFromApi(user.uid, formattedStartDate, formattedEndDate);

        let valueSuffix = UserService.usesStandardWeight(user) ? 'lbs' : 'kg';
        let weightChartData: Array<BasicAreaChartData> = [];
        if (!weightResponse.success) {
            for (let i = 0; i < daysDiff; i++) {
                let dateToUse = moment(formattedEndDate).subtract(i, 'd').format('YYYY-MM-DD');

                let emptyWeightEntry: BasicAreaChartData = {
                    //id: -1,
                    xAxisLabel: moment(dateToUse).format('MM/DD'),
                    value: 0,
                    valueSuffix
                }

                weightChartData.unshift(emptyWeightEntry);
            }
            setWeightChartData(weightChartData);
            return;
        }

        for (let i = 0; i <= daysDiff; i++) {
            let dateToUse = moment(formattedEndDate).subtract(i, 'd').format('YYYY-MM-DD');
            let found: any = _.find(weightResponse.weights, { date: dateToUse });

            let weightEntry: BasicAreaChartData;
            if (found) {
                weightEntry = {
                    xAxisLabel: moment(dateToUse).format('MM/DD'),
                    value: UserService.usesStandardWeight(user) ? LogDayService.roundNumber(found.weightLbs) : LogDayService.roundNumber(found.weightKg),
                    valueSuffix
                }

            } else {
                weightEntry = {
                    xAxisLabel: moment(dateToUse).format('MM/DD'),
                    value: 0,
                    valueSuffix
                }
            }
            weightChartData.unshift(weightEntry);
        }
        setWeightChartData(weightChartData);
    }

    useEffect(() => {
        if (user) loadWeights(user, startDate, endDate);
    }, [user, startDate, endDate]);

    return (
        <div className="logDayWeight">
            <Card
                title="Weight"
                style={{ marginTop: 20 }}
                size="small"
            >
                <BasicAreaChart width={'100%'} height={300} chartData={weightChartData as Array<BasicAreaChartData>} />
            </Card>
        </div>
    )
};
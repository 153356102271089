import moment from 'moment';
import { useEffect, useState } from "react";
import { collection, addDoc, setDoc, doc, query, getDocs, where, or, orderBy, limit, onSnapshot, deleteDoc, FirestoreError } from 'firebase/firestore';
import { db } from '../firebase';
import { Avatar, Button, Card, Divider, Flex, List, message as antdMessage, Modal, UploadProps, Upload } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { auth } from '../firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import MessagesService from '../services/MessagesService';
import _ from 'lodash';
//import AudioPlayer from '../components/AudioPlayer';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import TextArea from 'antd/es/input/TextArea';
import { AudioOutlined, AudioFilled, FileImageOutlined, EditOutlined, DeleteOutlined, InboxOutlined, CheckCircleOutlined } from '@ant-design/icons';
import axios from 'axios';
import Constants from '../services/Constants';
import CommonDataService from '../services/CommonDataService';
import { RcFile } from 'antd/es/upload';
const MicRecorder = require('mic-recorder-to-mp3');

export default function Messages() {
    const [messages, setMessages] = useState<Array<any>>([]);
    const [recentMessages, setRecentMessages] = useState<Array<any>>([]);
    const [message, setMessage] = useState<any>('');
    const [isRecording, setIsRecording] = useState<boolean>(false);
    const [audioBlobUrl, setAudioBlobUrl] = useState<string>('');
    const [previewImageUri, setPreviewImageUri] = useState<string>('');
    const [isAudioPreviewModalOpen, setIsAudioPreviewModalOpen] = useState<boolean>(false);
    const [isImageUploadModalOpen, setIsImageUploadModalOpen] = useState<boolean>(false);
    const [isUploadingMedia, setIsUploadingMedia] = useState<boolean>(false);
    const [isLoadingConvos, setIsLoadingConvos] = useState<boolean>(false);
    const [contactListVisible, setCcontactListVisible] = useState<boolean>(false);
    const [idToken, setIdToken] = useState<any>(null);

    const [messageApi, contextHolder] = antdMessage.useMessage();

    const [authenticatedUser] = useAuthState(auth);

    const location = useLocation();
    const { name, uid } = location.state;
    const { Dragger } = Upload;

    const [recorder] = useState(
        new MicRecorder({ bitRate: 128 })
    );

    const cardStyle: React.CSSProperties = {
        width: '100%',
    };

    const handleCancelAudioSend = () => {
        setIsAudioPreviewModalOpen(false);
    };

    const handleCancelImageUpload = () => {
        setIsImageUploadModalOpen(false);
    }

    const getIdToken = async () => {
        let token = await auth.currentUser?.getIdToken(true);
        setIdToken(token);
    }

    const loadConvo = () => {
        setIsLoadingConvos(true);

        const chatsRef = collection(db, "chats");

        const q = query(chatsRef,
            or((where('userSentUid', '==', uid)),
                where('userReceivedUid', '==', uid)),
            orderBy('createdAt', 'desc')
        );

        // const q = query(chatsRef,
        //     or((where('userSentUid', '==', uid), where('userReceivedUid', '==', authenticatedUser?.uid)),
        //         where('userReceivedUid', '==', uid), where('userSentUid', '==', authenticatedUser?.uid)),
        //     orderBy('createdAt', 'desc')
        // );

        const unsubscribe = onSnapshot(q, async (snapshot: any) => {
            let msgs: Array<any> = [];

            snapshot.docs.forEach(async (doc: any) => {
                let docData = doc.data();
                if ((docData.userSentUid !== uid && docData.userSentUid !== authenticatedUser?.uid) 
                    || (docData.userReceivedUid !== uid && docData.userReceivedUid !== authenticatedUser?.uid) ) return; //same as continue loop

                let msg = {
                    docId: doc.id,
                    _id: doc.data().id,
                    createdAt: doc.data().createdAt.toDate(),
                    text: doc.data().text,
                    //user: getUserForMsg(doc.data()),
                    userSentUid: doc.data().userSentUid,
                    userReceivedUid: doc.data().userReceivedUid,
                    audio: doc.data().audioFilename ?? null,
                    audioFilename: doc.data().audioFilename ?? null,
                    image: doc.data().imageFilename ?? null,
                    imageFilename: doc.data().imageFilename ?? null,
                    isEditing: false
                };

                msgs.unshift(msg);
            });

            let msgsWithMedia = _.filter(msgs, (m) => { return m.audio !== null || m.image !== null; });

            if (msgsWithMedia && msgsWithMedia.length && msgsWithMedia.length > 0) {
                //note: we could do this only 

                // if (messageAudioRefs && messageAudioRefs.current && messageAudioRefs.current.length && messageAudioRefs.current.length > 0) {
                //     _.forEach(messageAudioRefs.current, (msg:any) => {
                //         messageAudioRefs.current.pop();
                //     })
                // }

                let signedUrls = await loadSignedUrls();
                for (const msg of msgsWithMedia) {
                    //let signedUrls = CommonDataService.getMediaSignedUrls();

                    // if (!signedUrls[msg.audio] && !signedUrls[msg.image]) {
                    //     await loadSignedUrls();
                    //     signedUrls = CommonDataService.getMediaSignedUrls();
                    // }

                    if (msg.audio) {
                        msg['audio'] = signedUrls[msg.audio];
                    }
                    else if (msg.image) {
                        msg['image'] = signedUrls[msg.image];
                    }
                }
            }


            setMessages(msgs);
            scrollToBottom();
        }, error => {
            console.error('error: ', error);
        });
        
        // TODO: this timeout needs to be replaced with something that triggers when the conversation is completely loaded into the DOM
        setTimeout(() => {
            setIsLoadingConvos(false);
            scrollToBottom();
        }, 1000);
        
        //todo: call loadSignedUrls()
        return unsubscribe;
    }

    const loadSignedUrls = async () => {
        let res = null;

        try {
            setIsUploadingMedia(true);
            res = await MessagesService.getAudioMessage(uid);
        } finally {
            setIsUploadingMedia(false);
        }

        if (res && res.audio && res.audio.length && res.audio.length > 0) {
            let lookup: any = {};
            res.audio.forEach((audio: any) => {
                lookup[audio.filename] = audio.signedUrl;
            })

            CommonDataService.setMediaSignedUrls(lookup);
            return lookup;
        }
    }

    const loadRecentConvos = () => {
        const chatsRef = collection(db, "lastSentChat");
        // Create a query against the collection.
        const q = query(chatsRef, where('userReceivedUid', '==', authenticatedUser?.uid), orderBy('createdAt', 'desc'));

        let unsubscribe: any = undefined;

        let defaultMsg: any = {
            chatDocId: null,
            //id: doc.data().id,
            createdAt: new Date(),
            text: `No messages from ${name}`,
            userSentUid: uid,
            userReceivedUid: authenticatedUser?.uid,
            userSentName: name,
            isRead: false
        };

        const swapArrayLocs = (arr: [], index1:number, index2:number) => {
            [arr[index1], arr[index2]] = [arr[index2], arr[index1]]
        }

        unsubscribe = onSnapshot(q, async (snapshot: any) => {
            let recentMsgs: any = [];

            snapshot.docs.forEach(async (doc: any) => {
                let msg = {
                    chatDocId: doc.data().chatDocId,
                    //id: doc.data().id,
                    createdAt: doc.data().createdAt.toDate(),
                    text: doc.data().text,
                    userSentUid: doc.data().userSentUid,
                    userReceivedUid: doc.data().userReceivedUid,
                    userSentName: doc.data().userSentName,
                    isRead: doc.data().isRead
                    //user: getUserForMsg(doc.data())
                }

                recentMsgs.push(msg);
            });

            // let matchFoundIndex;
            // for (let i = 0; i < recentMsgs.length; i++) {
            //     if (recentMsgs[i].userReceivedUid === uid || recentMsgs[i].userSentUid === uid) {
            //         //todo: swap messages
            //     }
            // }

            let foundIndex = _.findIndex(recentMsgs, (msg: any) => { return msg.userReceivedUid === uid || msg.userSentUid === uid; });

            if (foundIndex >= 0) {
                swapArrayLocs(recentMsgs, 0, foundIndex);
            } else {
                recentMsgs.unshift(defaultMsg); //note: we always want the selected users messages up top. Others can follow
            }
            setRecentMessages(recentMsgs);
        }, (error: FirestoreError) => {
            setRecentMessages([defaultMsg]);
        });
        return unsubscribe;
    }

    const onSend = async (msgDetails?: any) => {

        try {
            let msgText: string | null = null;
            if (msgDetails?.isEditing) {
                msgText = msgDetails?.item?.text.trim();
            } else {
                msgText = message;
                if (message) msgText = message.trim();
            }

            if (previewImageUri) {
                setPreviewImageUri('');
            }

            // if (recording) {
            //     message['audio'] = lastRecordedMsg;
            // }

            let createdAt = new Date();

            let sendSuccess: boolean = true;
            let upsertedDocId: any = null;

            let msg: any = { createdAt, userSentUid: authenticatedUser?.uid, userReceivedUid: uid, isRead: false };
            if (msgText) msg['text'] = msgText;
            if (!msgDetails.isEditing) {
                if (msgDetails && msgDetails.audioFilename) {
                    msg['audioFilename'] = msgDetails.audioFilename;
                    msg['text'] = "Audio Message";
                } else if (msgDetails && msgDetails.imageFilename) {
                    msg['imageFilename'] = msgDetails.imageFilename;
                }

                try {
                    let res = await addDoc(collection(db, 'chats'), msg);
                    upsertedDocId = res.id;
                } catch (ex) {
                    sendSuccess = false;
                }
            } else if (!msgDetails.audioFilename) {
                if (msgDetails?.item?.docId) {
                    try {
                        await setDoc(doc(db, "chats", msgDetails?.item?.docId), msg);
                        upsertedDocId = msgDetails?.item?.docId;
                    } catch {
                        sendSuccess = false;
                    }
                }
            }

            if (sendSuccess && upsertedDocId) {
                let docData: any = { //note: currUser.uid is the id here
                    createdAt,
                    userSentUid: authenticatedUser?.uid,
                    userSentName: name,
                    userReceivedUid: uid,
                    chatDocId: upsertedDocId,
                    isRead: false
                };
                if (msgText) docData['text'] = msgText;
                else docData['text'] = 'media message'; //note: if text is not set, we can assume it's a media message (image, video, audio, etc)

                if (authenticatedUser?.uid) {
                    await setDoc(doc(db, "lastSentChat", authenticatedUser?.uid), docData);
                }
            }
        } finally {
            setMessage('');
            scrollToBottom();
        }
    }

    const sendMediaMsg = async (type: string) => {
        //todo: show loader
        const fd = new FormData();
        fd.append('media', CommonDataService.getMediaFile());

        try {
            setIsUploadingMedia(true);

            const res = await axios({
                method: 'POST',
                url: `${Constants.apiBaseUrl}/messages`,
                data: fd,
                headers: {
                    Accept: 'application/json',
                    "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${idToken}`
                }
            });

            if (res && res.data && res.data.filename && res.data.signedUrl) {
                await updateSignedUrlsAndSendMedia(res.data, type);
                // let filename = res.data.filename;
                // let signedUrls = CommonDataService.getMediaSignedUrls();
                // signedUrls[filename] = res.data.signedUrl;

                // CommonDataService.setMediaSignedUrls(signedUrls);

                // let msgDetails: any = {};
                // if (type === 'audio') {
                //     msgDetails['audioFilename'] = filename;
                // } else if (type === 'image') {
                //     msgDetails['imageFilename'] = filename;
                // }

                // await onSend(msgDetails);
            } else {
                throw new Error('unable to retrieve signedUrl');
            }
        } catch (err) {
            messageApi.open({
                type: 'error',
                content: 'Unable to save media. Please try again later.',
                duration: 5, //5 min max recording
            });
        } finally {
            setIsUploadingMedia(false);
            scrollToBottom();
        }
    }

    const updateSignedUrlsAndSendMedia = async (responseData: any, type: string) => {
        let filename = responseData.filename;
        let signedUrls = CommonDataService.getMediaSignedUrls();
        signedUrls[filename] = responseData.signedUrl;

        CommonDataService.setMediaSignedUrls(signedUrls);

        let msgDetails: any = {};
        if (type === 'audio') {
            msgDetails['audioFilename'] = filename;
        } else if (type === 'image') {
            msgDetails['imageFilename'] = filename;
        }

        await onSend(msgDetails);
    }

    const scrollToBottom = () => {
        const el: HTMLElement | null = document.querySelector('.conversation');
        if(el) {
            el.scrollTop = el.scrollHeight;
        }
    }

    useEffect(() => {
        if (recentMessages?.length > 0) {
            let found = _.find(recentMessages, { userSentUid: uid, isRead: false });
            if (found && found.chatDocId) {
                setDoc(doc(db, "lastSentChat", found.userSentUid), { isRead: true }, { merge: true });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recentMessages]);

    useEffect(() => {
        let unsubscribe: any = null;
        let unsubscribeRecentConvos: any = null;

        getIdToken();

        loadSignedUrls().then(() => {
            unsubscribe = loadConvo();
        }).catch(() => {
            // CommonDataService.showErrorToast('Unable to load audio messages');
            //unsubscribe = loadConvo();
        });

        unsubscribeRecentConvos = loadRecentConvos();

        return () => {
            try {
                //todo: ensure I stop any audio that is playing when we unload this view (can use refs from AudioPlayers)
                if (unsubscribe) unsubscribe();
                if (unsubscribeRecentConvos) unsubscribeRecentConvos();
            } catch { /* intentional no-op */ }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uid]);

    const getMessageBubbleContainerClassNames = (item: any) => {
        return classNames(
            // 'message-bubble',
            // 'pull-right',
            {
                'message-bubble-edit': item.isEditing,
                'message-bubble': !item.audio && !item.isEditing,
                'message-bubble-audio': item.audio,
                'message-sent': item.userSentUid === authenticatedUser?.uid,
                'message-received': item.userReceivedUid === authenticatedUser?.uid
            }
        )
    };

    const getMessageBubbleClassNames = (item: any) => {
        return classNames(
            {
                'message-sent-bubble': !item.image && item.userSentUid === authenticatedUser?.uid,
                'message-received-bubble': !item.image && item.userReceivedUid === authenticatedUser?.uid
            }
        )
    };

    const getRecentMessageClassNames = (item: any) => {
        return classNames({
            'recent-msg': true,
            'recent-msg-unread': !item.isRead
        })
    };

    const renderMessageTitle = (item: any) => {
        let currUserSentMsg = item.userSentUid === authenticatedUser?.uid;
        let createdAtMoment = moment(item.createdAt);
        let formattedDateTime = createdAtMoment.format('MM/DD/YYYY hh:mm a');

        const deleteMessage = async () => {
            let currUser = CommonDataService.getCurrentUser();

            const chatsRef = collection(db, "chats");
            let q = await query(
                chatsRef,
                where('userSentUid', '==', authenticatedUser?.uid),
                orderBy('createdAt', 'desc'),
                limit(5)
            );

            const querySnapshot = await getDocs(q);

            if (querySnapshot.docs.length && querySnapshot.docs.length > 0) {
                if (querySnapshot.docs.length === 1) {
                    //we will need to delete the lastSentChat record for the user
                    await deleteDoc(doc(db, 'lastSentChat', currUser.uid));
                } else if (querySnapshot.docs[0].id === item.docId) {
                    let priorSentDoc = querySnapshot.docs[1];
                    let priorSentData = priorSentDoc.data();
                    let lastSentMsg: any = {
                        chatDocId: priorSentDoc.id,
                        createdAt: priorSentData.createdAt,
                        isRead: priorSentData.isRead,
                        //text: priorSentData.text,
                        userReceivedUid: priorSentData.userReceivedUid,
                        userSentName: `${currUser.firstName} ${currUser.lastName}`,
                        userSentUid: priorSentData.userSentUid
                    }
                    if (priorSentData.text) lastSentMsg['text'] = priorSentData.text;

                    await setDoc(doc(db, "lastSentChat", currUser.uid), lastSentMsg);
                }
            }

            await MessagesService.deleteMessage(item.docId);

            await deleteDoc(doc(db, 'chats', item.docId));
        }

        const renderEditOptions = () => {
            if (item.image || item.audio) return (<DeleteOutlined style={{ marginLeft: 7, color: 'red' }} onClick={deleteMessage} />);
            else return (
                <>
                    <EditOutlined
                        style={{ color: 'green' }}
                        onClick={() => {
                            let msgsCopy = _.cloneDeep(messages);
                            let found = _.find(msgsCopy, { docId: item.docId });
                            found.isEditing = !found.isEditing;
                            setMessages(msgsCopy);
                        }} />
                    <DeleteOutlined style={{ marginLeft: 7, color: 'red' }} onClick={deleteMessage} />
                </>);
        }

        if (!currUserSentMsg) {
            return (
                <div>
                    {name}, {formattedDateTime}
                </div>
            );
        } else {
            return (
                <div style={{ textAlign: 'right' }}>
                    {formattedDateTime} {renderEditOptions()}
                </div>
            );
        }
    }

    const renderMessageBubbleContent = (item: any) => {
        if (item.image) {
            return (
                <img style={{ maxWidth: 300, maxHeight: 300, borderRadius: 7 }} src={item.image} alt="media message" />
            )
        } else if (item.audio) {
            return (<AudioPlayer src={item.audio} autoPlay={false} autoPlayAfterSrcChange={false} showSkipControls={false} showFilledVolume={false} showFilledProgress={false} />)

        } else {
            if (item.isEditing) {
                return (
                    <div>
                        <TextArea
                            rows={4}
                            bordered={true}
                            placeholder="Enter your message..."
                            value={item.text}
                            onChange={(e) => {
                                let msgsCopy = _.cloneDeep(messages);
                                let found = _.find(msgsCopy, { docId: item.docId });
                                found.text = e.target.value;
                                setMessages(msgsCopy);
                            }} />
                        <Flex justify={'end'} style={{ marginTop: 15 }}>
                            <Button
                                type="primary"
                                onClick={() => onSend({ isEditing: true, item })}>
                                Update
                            </Button>
                        </Flex>
                    </div>
                );
            } else {
                return (<>{item.text}</>)
            }
        }
    }

    const startRecording = () => {
        recorder.start().then(() => {
            setIsRecording(true);

            messageApi.open({
                type: 'loading',
                content: 'Recording has started, click the microphone again to stop recording.',
                duration: 300, //5 min max recording
            });
        }).catch((e: any) => {
            messageApi.open({
                type: 'error',
                content: 'Unable to start recording. Please ensure that you have provided access to your microphone.',
                duration: 5, //5 min max recording
            });

            setIsRecording(false);
        });
    };

    const stopRecording = () => {
        messageApi.destroy();
        setIsRecording(false);

        recorder
            .stop()
            .getMp3().then(([buffer, blob]: Array<any>) => {
                const file = new File(buffer, `${authenticatedUser?.uid}_${new Date().getTime()}.mp3`, {
                    type: blob.type,
                    lastModified: Date.now()
                });

                CommonDataService.setMediaFile(file);

                const blobURL = URL.createObjectURL(blob);
                setAudioBlobUrl(blobURL);

                setIsAudioPreviewModalOpen(true);

                // const player = new Audio(blobURL);
                // player.play();

            }).catch((e: any) => {
                alert('We could not retrieve your message');
                console.error(e);
            });
    }

    const handleBeforeImageUpload = (file: RcFile) => {
        // custom rename file name
        let newName = `${authenticatedUser?.uid}_${new Date().getTime()}`;
        const extension = file.name.split('.').pop()
        newName = `${newName}.${extension}`;

        // replace origin File
        const newFile = new File([file], newName, { type: file.type })

        if (newFile.type.split('/')[0].toLowerCase() !== 'image') {
            console.error('not an image');
            messageApi.error(`Sorry, you can only upload image files.`);
            return false;
        }

        return newFile;
    }

    const uploadProps: UploadProps = {
        name: 'media',
        multiple: true,
        //onRemove: () => false,
        //action: 'https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188',
        action: `${Constants.apiBaseUrl}/messages`,
        method: 'POST',
        headers: {
            // Accept: 'application/json',
            // "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${idToken}`
        },
        beforeUpload: handleBeforeImageUpload,
        onChange(info) {
            const { status } = info.file;
            if (status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (status === 'done') {
                if (info?.file?.response) {
                    messageApi.success(`the file uploaded successfully.`);
                    updateSignedUrlsAndSendMedia(info?.file?.response, 'image');
                } else {
                    messageApi.error(`the file upload failed.`);
                }
            } else if (status === 'error') {
                messageApi.error(`the file upload failed.`);
            }
        },
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
        showUploadList: {
            showDownloadIcon: false,
            //downloadIcon: 'Download',
            showRemoveIcon: false,
            //removeIcon: <></>,
        },
    };

    return (
        <div className="messages">
            <h1>Messages</h1>
            <Card className="messages-wrap" style={cardStyle}>
                <Flex className="messages-container">
                    <Flex className={contactListVisible ? "contact-list-wrap open" : "contact-list-wrap"}>
                        {/* clickable element to toggle some classes */}
                        <button className={contactListVisible ? "mobile-list-toggle open" : "mobile-list-toggle"} onClick={() => {
                            setCcontactListVisible(!contactListVisible)
                        }}></button>
                        <List
                            className="contact-list"
                            style={{ width: '100%' }}
                            itemLayout="horizontal"
                            dataSource={recentMessages}
                            renderItem={(item, index) => (
                                <List.Item
                                    key={index}
                                    className={uid === item.userSentUid ? "contact active" : "contact"}
                                    onClick={() => {
                                        setCcontactListVisible(false)
                                    }}
                                >
                                    <Link className="contact-link" to={`/messages`} state={{ uid: item.userSentUid, name: item.userSentName }}>
                                        <List.Item.Meta
                                            className={getRecentMessageClassNames(item)}
                                            // avatar={<Avatar src={`https://xsgames.co/randomusers/avatar.php?g=pixel&key=${index}`} />}
                                            title={item.userSentName}
                                            description={item.text}
                                        />
                                        {/* <div style={{paddingRight: 10}}>test</div> */}
                                    </Link>
                                </List.Item>
                            )}
                        />
                    </Flex>
                    <Flex className="divider">
                        <Divider type="vertical" style={{ height: "100%" }} />
                    </Flex>
                    <Flex className="messages-wrap" vertical justify="space-between">
                        <div className="conversation">
                            {isLoadingConvos && <div className="loading"><span className="dot"></span><span className="dot"></span><span className="dot"></span></div>}
                            {
                                messages?.length > 0 &&
                                messages.map((item: any, index: any) => (
                                    <div key={index}>
                                        <div className={getMessageBubbleContainerClassNames(item)}>
                                            {renderMessageTitle(item)}
                                            <div className={getMessageBubbleClassNames(item)}>
                                                {renderMessageBubbleContent(item)}
                                            </div>
                                        </div>
                                        <div style={{ clear: 'both' }}></div>
                                    </div>
                                ))
                            }
                            {messages?.length <= 0 && <div style={{marginBottom: 20, textAlign: 'center'}}>No message history. Send the first message to {name}!</div>}
                        </div>

                        <div className='chat-container'>
                            <TextArea
                                rows={4}
                                bordered={false}
                                placeholder="Enter your message..."
                                value={message}
                                onChange={(e) => setMessage(e.target.value)} />
                            <Flex justify={'space-between'} style={{ marginTop: 15 }}>
                                <div>
                                    <FileImageOutlined
                                        style={{ fontSize: 24 }}
                                        onClick={() => setIsImageUploadModalOpen(true)} />
                                    {contextHolder}
                                    {isRecording ? <AudioFilled onClick={stopRecording} style={{ fontSize: 24, marginLeft: 10 }} /> : <AudioOutlined onClick={startRecording} style={{ fontSize: 24, marginLeft: 10 }} />}
                                </div>
                                <Button
                                    type="primary"
                                    onClick={onSend}>
                                    Send
                                </Button>
                            </Flex>

                        </div>
                    </Flex>
                </Flex>
                <Modal
                    open={isAudioPreviewModalOpen}
                    title="Confirm Audio Before Sending"
                    onCancel={handleCancelAudioSend}
                    footer={[
                        <Button key="back" onClick={handleCancelAudioSend}>
                            Cancel
                        </Button>,
                        <Button key="submit" type="primary" onClick={() => {
                            setIsAudioPreviewModalOpen(false);
                            sendMediaMsg('audio');
                        }}>
                            Send Audio
                        </Button>
                    ]}>
                    <AudioPlayer src={audioBlobUrl} autoPlay={true} autoPlayAfterSrcChange={true} showSkipControls={false} showFilledVolume={false} showFilledProgress={false} />
                </Modal>
                <Modal
                    title="Upload Image"
                    open={isImageUploadModalOpen}
                    onCancel={handleCancelImageUpload}
                    footer={[
                        <Button key="back" onClick={handleCancelImageUpload}>
                            Done
                        </Button>
                    ]}>
                    <Dragger {...uploadProps}>
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">Click or drag file to this area to upload</p>
                        <p className="ant-upload-hint">
                            Support for a single or bulk upload. Strictly prohibited from uploading company data or other
                            banned files.
                        </p>
                    </Dragger>
                </Modal>
            </Card>
        </div>
    )
}
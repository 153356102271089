import { useEffect, useMemo, useState } from "react";
import { CoachingProgramService } from "../../services/CoachingProgramService";
import WeeklyCheckinService from "../../services/WeeklyCheckinService";
import { CheckinFormProps } from "../../types/WeeklyCheckinTypes"
import { EditOutlined } from '@ant-design/icons';
import Paragraph from "antd/lib/typography/Paragraph";
import TextArea from 'antd/es/input/TextArea';
import { Button, message, Modal } from "antd";
import _ from "lodash";

export interface CheckinFormEntry {
    id: number,
    maxLength: number,
    title: string,
    errorMsg?: string,
    subQuestions?: Array<CheckinFormEntry>,
    type?: string
}

export default function CheckinForm({ uid, coachingProgram, formEntries, week, initialFormResponse, coachResponse, onCheckinComplete }: CheckinFormProps) {
    const [userFormResponses, setUserFormResponses] = useState<any>(null);
    const [coachFormResponses, setCoachFormResponses] = useState<any>(null);
    const [isCheckinComplete, setIsCheckinComplete] = useState<any>(false);
    const [newCoachResponses, setNewCoachResponses] = useState<any>({});
    const [newCoachResponseWeek, setNewCoachResponseWeek] = useState<any>();
    const [showCoachResponseAlertWindow, setShowCoachResponseAlertWindow] = useState<any>(false);

    const [messageApi, contextHolder] = message.useMessage();

    // const loadCheckinTemplate = async () => {
    //     let res = await WeeklyCheckinService.getWeeklyCheckinTemplate();//.getWeeklyCheckinTemplate();

    //     if (initialFormResponse) {
    //         setFormResponses(initialFormResponse);
    //         setIsCheckinComplete(true);
    //     }

    //     if (res?.form) {
    //         setFormEntries(res.form);
    //     }
    // }

    //todo: ensure we send an alert if a response wasn't submitted and prevent them from posting. Also on submit save response

    useEffect((): any => {
        let isSubscribed = true;

        if (isSubscribed) {
            if (initialFormResponse) {
                setUserFormResponses(initialFormResponse);
                setIsCheckinComplete(true);
            }

            if (coachResponse) setCoachFormResponses(coachResponse);

            let currWeek = new CoachingProgramService().getCurrentWeekOfCoachingProgram(coachingProgram);
            setIsCheckinComplete(currWeek != week);
        }

        return () => {
            isSubscribed = false;
        }

    }, []);

    const getUserFormResponseFromId = (id: number) => {
        if (userFormResponses?.hasOwnProperty(`id${id}`)) {
            return userFormResponses[`id${id}`];
        }
        return false;
    }

    const getCoachResponseFromId = (id: number) => {
        if (coachFormResponses?.hasOwnProperty(`id${id}`)) {
            return coachFormResponses[`id${id}`];
        }
        return false;
    }

    // const onUserFormResponseInputChanged = (form: CheckinFormEntry, value: string) => {
    //     for (let i = 0; i < formEntries.length; i++) {
    //         if (formEntries[i].id == form.id) {
    //             let diff = value?.length - form?.maxLength;
    //             //not sure how, but this updates UI without having to call setFormEntries({...formEntries})
    //             formEntries[i]['errorMsg'] = (value?.length > form?.maxLength) ? `exceeded maximum message length by ${diff} characters` : '';
    //         }
    //     }

    //     let formResponseCopy = userFormResponses;
    //     if (value) formResponseCopy[`id${form.id}`] = value;
    //     else delete formResponseCopy[form.id];
    //     setUserFormResponses({ ...formResponseCopy });
    // }

    const onCoachFormResponseInputChanged = (form: CheckinFormEntry, value: string) => {
        // for (let i = 0; i < formEntries.length; i++) {
        //     if (formEntries[i].id == form.id) {
        //         let diff = value?.length - form?.maxLength;
        //         //not sure how, but this updates UI without having to call setFormEntries({...formEntries})
        //         formEntries[i]['errorMsg'] = (value?.length > form?.maxLength) ? `exceeded maximum message length by ${diff} characters` : '';
        //     }
        // }

        let newCoachResponsesCopy = newCoachResponses;
        if (value) newCoachResponsesCopy[`id${form.id}`] = value;
        else delete newCoachResponsesCopy[form.id];
        setNewCoachResponses({ ...newCoachResponsesCopy });
    }

    const onSubmitCoachResponse = async () => {
        let propsNotRespondedTo:Array<string> = [];
        for (var prop in userFormResponses) {
            if (!Object.prototype.hasOwnProperty.call(newCoachResponses, prop)) {
                propsNotRespondedTo.push(prop);
            }
        }

        if (propsNotRespondedTo.length > 0) {
            setShowCoachResponseAlertWindow(true);
            //await completeCoachResponseSubmission();
        } else {
            await completeCoachResponseSubmission();
        }
    }

    const completeCoachResponseSubmission = async () => {
        let success = await WeeklyCheckinService.saveWeeklyCheckinResponse(coachingProgram.id as number, newCoachResponses, week);

        if (success) {
            messageApi.open({
                type: 'success',
                content: 'Thanks! Your weekly checkin response has been recorded.',
            });

            setCoachFormResponses({...newCoachResponses});
            setShowCoachResponseAlertWindow(false);
        } else {
            messageApi.open({
                type: 'error',
                content: 'Sorry, we were unable to process your request at this time',
            });
        }
    }

    return (
        <div className="checkin-form">
            {contextHolder}
            {/* todo: look at following code in the WeeklyCheckins.tsx file and port that over here const getCheckinWeek = (formEntries: Array<CheckinFormEntry>) => { */}
            {(formEntries && formEntries?.length > 0) && formEntries.map((form: CheckinFormEntry, index: React.Key) => (
                <div key={index} style={{ marginTop: 30 }}>
                    <div style={{ marginBottom: 5, fontSize: 18 }}>{form.title}</div>
                    {!form.subQuestions && <>
                        {(isCheckinComplete && getUserFormResponseFromId(form.id)) && <div className="user-response">{getUserFormResponseFromId(form.id)}</div>}
                        {(isCheckinComplete && !getUserFormResponseFromId(form.id)) && <div className="user-response">N/A</div>}
                        {(coachFormResponses && getCoachResponseFromId(form.id)) && <div className="coach-response">{getCoachResponseFromId(form.id)}</div>}
                        {(!coachFormResponses && getUserFormResponseFromId(form.id)) && <TextArea
                                style={{marginTop: 10}}
                                rows={4}
                                placeholder="Enter your message..."
                                onChange={(e) => onCoachFormResponseInputChanged(form, e.target.value)} 
                                />}
                    </>}
                    {(form && form.subQuestions && form.subQuestions?.length > 0) && form.subQuestions.map((entry: CheckinFormEntry, j: React.Key) => (
                        <div key={j}>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 20 }}>
                                <div style={{ width: '75%', paddingTop: 10 }}>
                                    <div>{entry.title}</div>
                                </div>
                                <div>
                                    {(isCheckinComplete && getUserFormResponseFromId(entry.id)) && <div className="user-response" style={{maxWidth: 100}}>{getUserFormResponseFromId(entry.id)}</div>}
                                    {(isCheckinComplete && !getUserFormResponseFromId(entry.id)) && <div className="user-response" style={{maxWidth: 100}}>N/A</div>}
                                </div>
                            </div>
                            {(coachFormResponses && getCoachResponseFromId(entry.id)) && <div className="coach-response">{getCoachResponseFromId(entry.id)}</div>}
                            {(!coachFormResponses && getUserFormResponseFromId(entry.id)) && <TextArea
                                style={{marginTop: 10}}
                                rows={4}
                                placeholder="Enter your message..."
                                onChange={(e) => onCoachFormResponseInputChanged(entry, e.target.value)} 
                                />}
                        </div>
                    ))}
                </div>
            ))}
            {(!coachFormResponses && userFormResponses) && 
                <div style={{marginTop: 20, marginBottom: 20, textAlign: 'center'}}>
                    <Button type="primary" style={{marginRight: 20}} onClick={onSubmitCoachResponse}>Submit Checkin Responses</Button>
                </div>}
                <Modal
                    title="Continue without completing all responses?"
                    open={showCoachResponseAlertWindow}
                    onOk={completeCoachResponseSubmission}
                    onCancel={() => setShowCoachResponseAlertWindow(false)}
                    okText="Send Without Answering All Questions!">
                        <div>Not all user responses have been answered. This is ok as sometimes it doesn't make sense to answer all questions. Please make sure you have answered every question before submitting.</div>
                </Modal>
        </div>
    );
}
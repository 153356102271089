import axios from "axios";
import { getAuth } from "firebase/auth";
import { ClientNoteProps } from "../components/Notes/ClientNote";
import Constants from "./Constants";


interface INoteService {
    getNotesFromApi: (uid: string) => any;
    createUserNote: (clientNote:ClientNoteProps) => any;
    updateUserNote: (clientNote:ClientNoteProps) => any;
    deleteUserNote: (id:any) => any;
}

class NoteService implements INoteService {
    getNotesFromApi = async(uid: string) => {
        const auth = getAuth();
        let idToken = await auth.currentUser?.getIdToken(true);
    
        try {
            if (!idToken) throw new Error("invalid token");

            let res = await axios({
                url: `${Constants.apiBaseUrl}/note/uid/${uid}`,
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${idToken}`
                }
            });
            return { success: true, notes: res.data };
        } catch (err) {
            //could alert there was error or that they are in offline mode
            return { success: false };
        }
    }

    createUserNote = async (clientNote:ClientNoteProps) => {
        const auth = getAuth();
        let idToken = await auth.currentUser?.getIdToken(true);
    
        try {
            if (!idToken) throw new Error("invalid token");
            let res = await axios({
                url: `${Constants.apiBaseUrl}/note`,
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${idToken}`
                },
                data: clientNote
            });
            return { success: true, note: res.data };
            //return { syncDate: res.data.syncDate, key: res.data.key };
        } catch (err) {
            //return { syncDate: null, key: null };
            return { success: false };
        }
    }

    updateUserNote = async (clientNote:ClientNoteProps) => {
        const auth = getAuth();
        let idToken = await auth.currentUser?.getIdToken(true);
    
        try {
            if (!idToken) throw new Error("invalid token");
            let res = await axios({
                url: `${Constants.apiBaseUrl}/note`,
                method: 'PATCH',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${idToken}`
                },
                data: clientNote
            });
            return { success: true, note: res.data.note };
            //return { syncDate: res.data.syncDate, key: res.data.key };
        } catch (err) {
            //return { syncDate: null, key: null };
            return { success: false };
        }
    }

    deleteUserNote = async (id:any) => {
        if (!id) return false;

        const auth = getAuth();
        let idToken = await auth.currentUser?.getIdToken(true);
    
        try {
            if (!idToken) throw new Error("invalid token");
            let res = await axios({
                url: `${Constants.apiBaseUrl}/note/${id}`,
                method: 'DELETE',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${idToken}`
                },
            });
            return { success: true };
        } catch (err) {
            return { success: false };
        }
    }
}

export default new NoteService() as INoteService
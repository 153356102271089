import { FireFilled } from "@ant-design/icons";
import { Divider, Flex, Collapse } from "antd";
import { CompressedServingInfo } from "../types/LogTypes";

export type NutritionInfoProps = {
    serving: CompressedServingInfo,
    extraTitle: string
};

export default function NutritionInfo ({serving, extraTitle=''}:NutritionInfoProps) {
    return (
        <div className="nutritionInfo">
            <Collapse
                style={{marginBottom: '2rem'}}
                items={[{
                    key: '1',
                    label:' Nutrition Info '+extraTitle,
                    children: <div>
                        {(serving.cals || serving.cals === 0) && <><Flex className="calories" justify={'space-between'}><div>Calories:</div><div> {serving.cals} <FireFilled /></div></Flex>
                        <Divider style={{marginTop:5, marginBottom:5}} /></>}
                        {(serving.pro || serving.pro === 0) && <><Flex className="protein" justify={'space-between'}><div>Protein:</div><div> {serving.pro} g</div></Flex>
                        <Divider style={{marginTop:5, marginBottom:5}} /></>}
                        {(serving.carb || serving.carb === 0) && <><Flex className="carbs" justify={'space-between'}><div>Carbohydrates:</div><div> {serving.carb} g</div></Flex>
                        <div style={{marginLeft: 10}}>
                            {(serving.fib || serving.fib === 0) && <Flex style={{marginTop:5, marginBottom:5}} justify={'space-between'}><div>Fiber:</div><div> {serving.fib} g</div></Flex>}
                            {(serving.sug || serving.sug === 0) && <Flex style={{marginTop:5, marginBottom:5}} justify={'space-between'}><div>Total Sugars:</div><div> {serving.sug} g</div></Flex>}
                            {(serving.as || serving.as === 0) && <Flex style={{marginTop:5, marginBottom:5}} justify={'space-between'}><div>Added Sugars:</div><div> {serving.as} g</div></Flex>}
                        </div>
                        <Divider style={{marginTop:5, marginBottom:5}} /></>}
                        {(serving.fat || serving.fat === 0) && <><Flex className="fats" justify={'space-between'}><div>Total Fat:</div><div> {serving.fat} g</div></Flex>
                        <div style={{marginLeft: 10}}>
                            {(serving.sFat || serving.sFat === 0) && <Flex style={{marginTop:5, marginBottom:5}} justify={'space-between'}><div>Saturated Fat:</div><div> {serving.sFat} g</div></Flex>}
                            {(serving.polyFat || serving.polyFat === 0) && <Flex style={{marginTop:5, marginBottom:5}} justify={'space-between'}><div>Polyunsaturated Fat:</div><div> {serving.polyFat} g</div></Flex>}
                            {(serving.mFat || serving.mFat === 0) && <Flex style={{marginTop:5, marginBottom:5}} justify={'space-between'}><div>Monounsaturated Fat:</div><div> {serving.mFat} g</div></Flex>}
                            {(serving.tFat || serving.tFat === 0) && <Flex style={{marginTop:5, marginBottom:5}} justify={'space-between'}><div>Trans Fat:</div><div> {serving.tFat} g</div></Flex>}
                        </div>
                        <Divider style={{marginTop:5, marginBottom:5}} /></>}
                        {(serving.chol || serving.chol === 0) && <><Flex justify={'space-between'}><div>Cholesterol:</div><div> {serving.chol} mg</div></Flex>
                        <Divider style={{marginTop:5, marginBottom:5}} /></>}
                        {(serving.sod || serving.sod === 0) && <><Flex justify={'space-between'}><div>Sodium:</div><div> {serving.sod} mg</div></Flex>
                        <Divider style={{marginTop:5, marginBottom:5}} /></>}
                        {(serving.pot || serving.pot === 0) && <><Flex justify={'space-between'}><div>Potassium:</div><div> {serving.pot} mg</div></Flex>
                        <Divider style={{marginTop:5, marginBottom:5}} /></>}
                        {(serving.vD || serving.vD === 0) && <><Flex justify={'space-between'}><div>Vitamin D:</div><div> {serving.pot} mcg</div></Flex>
                        <Divider style={{marginTop:5, marginBottom:5}} /></>}
                        {(serving.vA || serving.vA === 0) && <><Flex justify={'space-between'}><div>Vitamin A:</div><div> {serving.pot} mcg</div></Flex>
                        <Divider style={{marginTop:5, marginBottom:5}} /></>}
                        {(serving.vC || serving.vC === 0) && <><Flex justify={'space-between'}><div>Vitamin C:</div><div> {serving.pot} mg</div></Flex>
                        <Divider style={{marginTop:5, marginBottom:5}} /></>}
                        {(serving.calc || serving.calc === 0) && <><Flex justify={'space-between'}><div>Calcium:</div><div> {serving.calc} g</div></Flex>
                        <Divider style={{marginTop:5, marginBottom:5}} /></>}
                        {(serving.ir || serving.ir === 0) && <Flex justify={'space-between'}><div>Iron:</div><div> {serving.ir} g</div></Flex>}
                    </div>
                }]}
            />
            
        </div>
    )
};
import { Button, Flex, Form, Input, message, Typography } from "antd";
import { useContext, useEffect, useState } from "react"
import appStore from '../../img/app-store.svg';
import googlePlay from '../../img/google-play-badge.png';
import { ProgramSignupContext } from "../../hooks/ContextHooks";
import { useNavigate, useParams } from "react-router-dom";
import { getAuth, createUserWithEmailAndPassword, signOut } from 'firebase/auth';
import { CoachingProgramService } from "../../services/CoachingProgramService";
import { useAuthState } from "react-firebase-hooks/auth";

export default function CreateUsernamePassword() {
    const navigate = useNavigate();
    const auth = getAuth();
    const [authenticatedUser, loading] = useAuthState(auth);
    const [messageApi, contextHolder] = message.useMessage();
    const [shouldCheckAuth, setShouldCheckAuth] = useState<boolean>(true);

    const { state, update }:any = useContext(ProgramSignupContext);
    let { pg } = useParams();



    useEffect(() => {
        if (shouldCheckAuth && !loading && authenticatedUser) {
            signOut(auth);
        }
    }, [pg, loading, authenticatedUser, shouldCheckAuth]);

    const onFinish = async (data: FieldType) => {
        // state.user.email = data.email;

        // update({ user: state.user });
        let coachingProgramService = new CoachingProgramService();

        let inviteResponse = await coachingProgramService.getPendingProgramInvite(Number(pg));

        if (!inviteResponse.success) {
            navigate("/login");
            return;
        }

        try {
            setShouldCheckAuth(false); //at this point we should not check auth again b/c we will need them to be signed in
            let result = await createUserWithEmailAndPassword(auth, data.email as string, data.password as string);

//             if (result?.user?.uid) {
//                 let currUser = state.user;
//                 currUser.uid = result?.user?.uid;
//                 update ({ user: currUser });
//             }

        } catch (error:any) {
            if (error.code === 'auth/email-already-in-use') {
                messageApi.open({
                    type: 'error',
                    content: 'Email is already registered. The email must be unique when registering.'
                });
                return;
            }
            messageApi.open({
                type: 'error',
                content: 'Sorry, an unkown error occurred'
            });
            return;
        }

        if (Number.isInteger(Number(pg))) {
            //@ts-ignore
            let requestData: CoachingProgramInviteUserRequestData = {
                inviteId: Number(pg),
                email: data.email as string
            };
            //note: special case where we simply want to update the email for them based on their inviteId
            let res = await coachingProgramService.inviteUser(requestData, true);

            if (!res) {
                messageApi.open({
                    type: 'error',
                    content: 'Sorry, an unkown error occurred. Please contact support'
                });
                return;
            }
        }

        navigate("/programSignup/1");
    };

    const onFinishFailed = (errorInfo: any) => {
        console.error('Failed:', errorInfo);
    };

    type FieldType = {
        email?: string;
        password?: string;
        confirmPassword?: string;
    };

    return (
        <>
            {contextHolder}
            <div className="createUsernamePassword" style={{ maxWidth: 300, margin: 'auto' }}>
                <Form
                    layout="vertical"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <Form.Item<FieldType>
                        label="Email Address"
                        name="email"
                        hasFeedback
                        rules={[{ required: true, message: 'Invalid email address', type: 'email' }]}
                        validateTrigger="onBlur"
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item<FieldType>
                        label="Password"
                        name="password"
                        hasFeedback
                        rules={[{ required: true, message: 'Password must be at least 8 characters', min: 8 }]}
                        validateTrigger="onBlur"
                    >
                        <Input.Password />
                    </Form.Item>

                    <Form.Item<FieldType>
                        label="ConfirmPassword"
                        name="confirmPassword"
                        validateTrigger="onBlur"
                        dependencies={['password']}
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: 'Please confirm your password!',
                            },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('Passwords must match'));
                                },
                            }),
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>

                    <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </>
    )
}
import { auth } from '../firebase';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import Footer from './Footer';
import CommonDataService from '../services/CommonDataService';

export default function ProtectedLayout () {
    const [user, loading] = useAuthState(auth);

    if (loading) return null; 

    if (!user) {
        return <Navigate to="/login" />;
    } else if (!CommonDataService.getCurrentUser()) {
        // note: we set setCurrentUser right after a successful admin login. So if they get here, they are logged in, but not admins.
        // Currently we will not allow them to access any  protected content.
        return <Navigate to="/error" />
    }

    return (
        <>
            <Outlet />
            <Footer />
        </>
    );
};
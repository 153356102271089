import { Button, Card } from "antd";
import _ from "lodash";
import LogDayService from "../services/LogDayService";
import { CompressedServingInfo, Meal, MealEntry } from "../types/LogTypes";
import { MacrosFooter, MacrosFooterProps } from "./MacrosFooter";

export type LogDayMealProps = {
    meals: Array<Meal>,
    onViewNutritionInfoClicked: (meal: Meal) => void;
};

export default function LogDayMeals({ meals, onViewNutritionInfoClicked }: LogDayMealProps) {

    const renderServingDetails = (mealEntry: MealEntry) => {
        let serving:CompressedServingInfo = _.find(mealEntry.servings, { sId: mealEntry.servingId }) as CompressedServingInfo;

        if (serving) {
            return (
                <>
                    <span>, {serving.sDesc}</span>
                    <span className="medium-grey"> ({LogDayService.roundNumber(mealEntry.numServings)} servings)</span>
                </>
            );
        } else {
            return <></>;
        }
    }

    const renderMealEntries = (meal:Meal) => {
        let mealEntries:Array<MealEntry> = meal.entries;

        if (mealEntries?.length > 0) {
            return (<>
                {
                    mealEntries.map((entry: MealEntry, index: any) => (
                        <div key={index}><span style={{fontWeight: 'bold'}}>{entry.foodName}</span> {renderServingDetails(entry)}</div>
                    ))
                }
            </>);
        } else {
            return (<>
                No entries logged yet. Start logging {meal.name}
            </>);
        }
    }

    const getMacroTotals = (entries: Array<MealEntry>) => {
        let totals: MacrosFooterProps = { cals: 0, protein: 0, fats: 0, carbs: 0 };

        _.forEach(entries, (entry: MealEntry) => {
            totals.cals += entry.loggedCalculatedServing.cals as number;
            totals.protein += entry.loggedCalculatedServing.pro as number;
            totals.carbs += entry.loggedCalculatedServing.carb as number;
            totals.fats += entry.loggedCalculatedServing.fat as number;
        })

        totals.cals = Math.round(totals.cals);

        return totals;
    }

    return (
        <div className="logDayMeals">
            {
                meals.map((meal: Meal, mealIndex: any) => (
                    <Card 
                        key={mealIndex} 
                        title={meal.name}
                        extra={ meal?.entries?.length > 0 ? <Button type="text" onClick={() => {
                            onViewNutritionInfoClicked(meal)
                        }}> View Full Nutrition Info</Button> : <></>}
                        style={{marginTop: 20}}>
                        {renderMealEntries(meal)}
                        <MacrosFooter {...getMacroTotals(meal.entries)} />
                    </Card>
                ))
            }
        </div>
    )
};
import axios from "axios";
import { getAuth } from "firebase/auth";
import { SimpleResponse, WeightLossGoalForApi } from "../types/DTOs";
import { WeightLossGoal } from "../types/GoalTypes";
import Constants from "./Constants";

type WeightLossGoalResponse = {
    success: boolean,
    weightLossGoals?: Array<WeightLossGoal>
}

interface IWeightLossGoalService {
    getWeightLossGoals: (uid: string) => Promise<WeightLossGoalResponse>;
    createWeightLossGoal: (weightLossGoal: WeightLossGoalForApi) => Promise<SimpleResponse>;
}

class WeightLossGoalService implements IWeightLossGoalService {
    getWeightLossGoals = async (uid: string) => {
        if (!uid) {
            console.error('uid is a required param');
            return { success: false };
        }

        const auth = getAuth();
        let idToken = await auth.currentUser?.getIdToken(true);

        try {
            if (!idToken) throw new Error("invalid token");

            let res = await axios({
                url: `${Constants.apiBaseUrl}/weightLossGoal/${uid}`,
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${idToken}`
                }
            });
            return { success: true, weightLossGoals: res.data };
        } catch (err) {
            //could alert there was error or that they are in offline mode
            return { success: false };
        }
    }

    createWeightLossGoal = async (weightLossGoal: WeightLossGoalForApi) => {
        const auth = getAuth();
        let idToken = await auth.currentUser?.getIdToken(true);

        try {
            if (!idToken) throw new Error("invalid token");
            let res = await axios({
                url: `${Constants.apiBaseUrl}/weightLossGoal`,
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${idToken}`
                },
                data: weightLossGoal
            });
            return { success: true };
        } catch (err) {
            return { success: false };
        }
    }
}

export default new WeightLossGoalService() as IWeightLossGoalService
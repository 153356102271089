import React, { PureComponent } from 'react';
import { BarChart, Bar, XAxis, ResponsiveContainer, ReferenceLine } from 'recharts';
import CommonDataService from '../services/CommonDataService';
import { LogDay } from '../types/LogTypes';

export type BasicBarChartData = {
    xAxisLabel: string;
    value: number;
    logDay: LogDay;
}

export type BasicBarChartProps = {
    width: number | string;
    height: number | string;
    chartColor: string;
    referenceLineValue: number;
    chartData: Array<BasicBarChartData>;
    onBarChartClicked: (data:any) => void;
};

export class BasicBarChart extends PureComponent<BasicBarChartProps> {

    handleBarClick = (data:any, index: number) => {
        this.props.onBarChartClicked(data);
    }

    render() {
        return (
            <div style={{ width: this.props.width, height: this.props.height }}>
                <ResponsiveContainer width="100%" height="100%">
                    <BarChart
                        width={150}
                        height={40}
                        data={this.props.chartData}
                        margin={{
                            top: 50,
                            right: 0,
                            left: 60,
                            bottom: 5,
                        }}>

                        <XAxis dataKey="xAxisLabel" />
                        {/* <YAxis /> */}
                        <Bar 
                            dataKey="value" 
                            fill={this.props.chartColor} 
                            label={{ position: 'top', fontSize: 12 }}
                            onClick={this.handleBarClick} />
                        <ReferenceLine 
                            y={this.props.referenceLineValue} 
                            label={{ position: 'left', value: this.props.referenceLineValue.toLocaleString(), fill: CommonDataService.getAppColors().darkTeal, fontWeight: 'bold', fontSize: 14 }} 
                            stroke={CommonDataService.getAppColors().darkTeal}
                            ifOverflow="extendDomain" />
                    </BarChart>
                </ResponsiveContainer>
            </div>
        );
    }
}

// @ts-nocheck
import { useEffect } from "react"
import { Link } from "react-router-dom"
//import { Helmet } from 'react-helmet'
//import { Link, useParams } from "react-router-dom"

//const SCRIPT_SRC = 'https://app.termly.io/embed-policy.min.js';

export default function PrivacyPolicy() {

    // const { slug } = useParams();
    // const { uuid } = usePolicyConfig(slug);

    useEffect(() => {
        window.scrollTo(0, 0);

        const loadPolicy = async () => {
            try {
                const script = document.createElement("script");
                script.src = "https://app.termly.io/embed-policy.min.js";
                script.async = true;
                await document.body.appendChild(script);
            } catch (e) {
                console.log('error loading script: ', e);
            }
        }

        loadPolicy();
    }, []);

    return(
        <>
            <div className="privacy-policy">
                
                <div className="close"><Link to={"/"}>Close</Link></div>

                
                <div name="termly-embed" data-id="9f43cbad-c92e-414e-a121-c096087f59de" data-type="iframe"></div>

                {/* <Helmet>
                    <script
                    type="text/javascript"
                    src={ SCRIPT_SRC }
                    />
                </Helmet>

                <div
                    data-id={ uuid }
                    data-type="iframe"
                    name="termly-embed"
                /> */}
            </div>
        </>

    )
}
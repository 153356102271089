import { Collapse, CollapseProps } from "antd";
import _ from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import CheckinForm from "../components/WeeklyCheckins/CheckinForm";
import { CoachingProgramService } from "../services/CoachingProgramService";
import NotificationService from "../services/NotificationService";
import WeeklyCheckinService from "../services/WeeklyCheckinService";
import { CoachingProgram } from "../types/CoreTypes";
import { CheckinFormEntry, UserCheckinForm, WeeklyCheckinProps } from "../types/WeeklyCheckinTypes";

export default function WeeklyCheckins() {
    const [formEntries, setFormEntries] = useState<Array<CheckinFormEntry>>([]);
    const [coachingProgram, setCoachingProgram] = useState<CoachingProgram>();
    const [weeklyCheckins, setWeeklyCheckins] = useState<Array<WeeklyCheckinProps>>();
    const [upcomingWeekMessage, setUpcomingWeekMessage] = useState<string>();
    const [upcomingWeekCheckinDate, setUpcomingWeekCheckinDate] = useState<string>();
    const [collapseItems, setCollapseItems] = useState<CollapseProps['items']>();

    const location = useLocation();
    const { user } = location.state;

    useEffect(() => {
        window.scrollTo(0, 0);

        loadCheckins();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    let loadCheckins = async () => {
        let res = await WeeklyCheckinService.getWeeklyCheckinTemplate(user.uid);

        if (res?.form) {
            setFormEntries(res.form);
        }

        let checkins:Array<UserCheckinForm> = await WeeklyCheckinService.getUserCheckins(user.uid);

        await buildWeeks(checkins, res.form);
    }

    const buildWeeks = async (checkins:Array<UserCheckinForm>, formEntries: Array<CheckinFormEntry>) => {
        let coachingProgram = await loadCoachingProgram();
        
        let currWeek = new CoachingProgramService().getCurrentWeekOfCoachingProgram(coachingProgram);

        //note: we need to always complete 1 week before we provide the checkin form
        currWeek = currWeek - 1;

        let hasCompletedCurrWeekCheckin:boolean = false;
        let wklyCheckins:Array<WeeklyCheckinProps> = [];
        let collapseItems:CollapseProps['items'] = [];
        for (let i=0; i<currWeek; i++) {
            let wk = i+1;
            let found = _.find(checkins, { week: wk });
            wklyCheckins.unshift({ week: wk, userCheckin: found as UserCheckinForm, expanded: wk === currWeek  });
            // collapseItems.unshift({ key: wk, label: `week ${wk}`, children: <>{getCheckinWeek(formEntries)}</>});
            collapseItems.unshift({ key: wk, label: `week ${wk}`, children: <CheckinForm {...{ uid: user.uid, coachingProgram, formEntries, week: wk, initialFormResponse: found?.userResponse, coachResponse: found?.coachResponse, onCheckinComplete: onCheckinComplete }} />});
            if (found && wk === currWeek) hasCompletedCurrWeekCheckin = true;
        }

        if (currWeek <= 0) {
            //setShowWelcomeMsg(true);
            let initialCheckinDate = moment(coachingProgram.startDateTime).add(7, 'day').format('dddd, MM/DD/YYYY');
            setUpcomingWeekCheckinDate(initialCheckinDate);
        } else if (hasCompletedCurrWeekCheckin) {
            let nextCheckinDate = moment(coachingProgram.startDateTime).add(currWeek+1, 'week').add(1, 'day').format('dddd, MM/DD/YYYY');
            setUpcomingWeekMessage(`The next weekly checkin will be available on:`);
            setUpcomingWeekCheckinDate(nextCheckinDate);
        }

        setWeeklyCheckins(wklyCheckins);
        setCollapseItems(collapseItems);

    }

    let loadCoachingProgram = async () => {
        let response = await new CoachingProgramService().getProgramByUid(user.uid);

        if (response) setCoachingProgram(response);
        return response;
    }

    const onCheckinComplete = (data: any) => {
        //scrollRef.current?.scrollToPosition(0);
    }

    return (
        <div className="weeklyCheckins">
            <h1>Weekly Checkins</h1>
            {collapseItems && collapseItems?.length > 0 && <Collapse items={collapseItems} defaultActiveKey={[collapseItems?.length]} />}
        </div>
    )
}
import { CloseOutlined, CopyTwoTone, HighlightOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Drawer, List, Modal, Select, message, Table, Tag, Space, Card, Typography, TableProps, InputRef, TableColumnType, Input, Flex, Checkbox, CheckboxProps } from "antd";
import type { ColumnsType } from 'antd/es/table';
import TextArea from "antd/lib/input/TextArea";
import _ from "lodash";
import moment from "moment";
import { useEffect, useState, useRef } from "react";
import ApplicationService from "../services/ApplicationService";
import { Application, ApplicationResponseV1 } from "../types/CoreTypes";
import type { FilterDropdownProps } from 'antd/es/table/interface';

export default function Applications() {
    type OnChange = NonNullable<TableProps<DataType>['onChange']>;
    type Filters = Parameters<OnChange>[1];

    type DataIndex = keyof DataType;

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef<InputRef>(null);

    const handleChange: OnChange = (pagination, filters, sorter) => {
        //console.log('Various parameters', pagination, filters, sorter);
        // setSortedInfo(sorter as Sorts);
        setFilteredInfo(filters);
    };

    const handleSearch = (
        selectedKeys: string[],
        confirm: FilterDropdownProps['confirm'],
        dataIndex: DataIndex,
    ) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters: () => void) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (dataIndex: DataIndex): TableColumnType<DataType> => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => {
                            clearFilters && handleReset(clearFilters);
                            handleSearch(selectedKeys as string[], confirm, dataIndex)
                        }}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Reset
                    </Button>
                    {/* <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({ closeDropdown: false });
                            setSearchText((selectedKeys as string[])[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button> */}
                </Space>
            </div>
        ),
        filterIcon: (filtered: boolean) => (
            <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
        ),
        onFilter: (value: any, record: any) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes((value as string).toLowerCase()),
        onFilterDropdownOpenChange: (visible: any) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        /*render: (text: any) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
            text
        ),*/
    });

    //const [formEntries, setFormEntries] = useState<Array<any>>([]);
    const [applicationTableData, setApplicationTableData] = useState<Array<DataType>>([]);
    const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
    const [isClosedDealModalOpen, setIsClosedDealModalOpen] = useState<boolean>(false);
    const [applicationDetails, setApplicationDetails] = useState<Array<any>>([]);
    const [isAddingNote, setIsAddingNote] = useState<boolean>();
    const [applicationNotes, setApplicationNotes] = useState<string>();
    const [filteredInfo, setFilteredInfo] = useState<Filters>({});

    const applicationsRef = useRef<any>(null);
    const selectedApplicationRef = useRef<any>(null);
    const [messageApi, contextHolder] = message.useMessage();

    const { Paragraph } = Typography;
    const _closedDealStatus: string = 'closed';

    interface DataType {
        key: string;
        id: number;
        name: string;
        email: string;
        howHearAboutUs: string;
        phone: string;
        socialMedia: string;
        status: string;
        createDate: string;
        isComplete: boolean;
    }

    useEffect(() => {
        window.scrollTo(0, 0);

        loadApplications();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loadApplications = async () => {
        let applicationResponse = await ApplicationService.getAll();

        if (applicationResponse.success) {
            const data: Array<DataType> = [];
            let applications = applicationResponse.applications;
            applicationsRef.current = applications;

            applications.forEach((application: Application, index: number) => {

                let name = `${application.response.firstName} ${application.response.lastName}`;

                let item: DataType = {
                    key: (index + 1).toString(),
                    id: application.id as number,
                    name,
                    email: application.response.email,
                    howHearAboutUs: application.response.howDidYouHearAboutUs ?? 'unknown',
                    phone: application.response.phone,
                    socialMedia: application.response.social,
                    status: application.status,
                    createDate: moment(application.createDate).local().format('MMM DD, YYYY hh:mm A'),
                    isComplete: application.completedDate ? true : false
                }

                data.push(item);
            })

            setApplicationTableData(data);
        }
    }

    const buildApplicationResponseDetail = (id: number) => {
        let found: any = _.find(applicationsRef?.current, { id: id });
        selectedApplicationRef.current = found;

        setApplicationNotes(found.notes);

        if (found) {
            let data = [
                { title: 'Application Received', description: moment(found.createDate).local().format('MMM DD, YYYY hh:mm A') },
                { title: 'Name', description: `${found.response.firstName} ${found.response.lastName}` },
                { title: 'Date of Birth', description: found.response.dob },
                { title: 'Email', description: found.response.email },
                { title: 'Phone', description: found.response.phone },
                { title: 'Socail Media', description: found.response.social },
                { title: 'What prompted you to reach out for help?', description: found.response.whyReachOut },
                { title: 'What are your biggest challenges or obstacles with your nutrition? ?', description: found.response.biggestChallenges },
                { title: 'Are you currently using any food tracking apps? ', description: found.response.currentlyTracking },
                { title: 'If not, are you open to using one? ', description: found.response.openToTracking ?? 'N/A' },
                { title: 'Have you ever worked with a nutritionist or participated in a weight management program?', description: found.response.piorNutritionist },
                { title: 'If you answered yes to the above question, what was your experience like?', description: found.response.priorNutritionistExperience },
                { title: 'On a scale of 1 to 10, how committed are you to making changes with your nutrition? ', description: found.response.howCommittedToChange },
                { title: 'Are you looking for an structured meal plan, or does something a bit more flexible sound appealing to you?', description: found.response.structuredMealPlanOrFlexible },
                { title: 'At this time, are you willing to make a financial investment in your nutrition goals and a coaching program? ', description: found.response.willingFinancialInvestment },
                { title: 'What is your monthly budget for nutrition coaching?', description: found.response.monthlyBudget },
                { title: 'Do you have any reservations about joining a nutrition coaching program? If so, please list them here.', description: found.response.reservationsAboutJoining },
                { title: 'Is there anyone else (spouse/significant other, parent, etc) who will influence your decision to invest in nutrition coaching?', description: found.response.anyoneInfluenceDecision },
                { title: 'If we decide we are a good fit to work together during our conversation, will you be ready to start within the next 30-60 days? ', description: found.response.readyToStartSoon },
                { title: 'If you answered no or maybe to the question above, please provide a brief explanation.', description: found.response.readyToStartSoonExplanation },
                { title: 'Last question! How did you hear about Eat Pray Lift Nutrition Coaching?', description: found.response.howDidYouHearAboutUs },
                { title: 'If they answered other: ', description: found.response.otherHearAboutUs }
            ];

            setApplicationDetails(data);
        }
    }

    const columns: ColumnsType<DataType> = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            render: (text, item) => <a onClick={() => {
                buildApplicationResponseDetail(item.id);
                setIsDrawerOpen(true);
            }}>{text}</a>,
            ...getColumnSearchProps('name'),
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            //responsive: ['md'],
            render: (text, { status, id }) =>
                <>
                    {status === _closedDealStatus && <Tag color={'green'}>Closed Deal</Tag>}
                    {status !== _closedDealStatus &&
                        <Select
                            defaultValue={status ? `${id}_${status}` : 'needToRespond'}
                            style={{ width: 170 }}
                            onChange={handleStatusChange}
                            options={[
                                { value: `${id}_needToRespond`, label: 'Need To Respond' },
                                { value: `${id}_responded`, label: 'Responded' },
                                { value: `${id}_thinking`, label: 'Thinking About It' },
                                { value: `${id}_hotConvo`, label: 'HOT Convo' },
                                { value: `${id}_notIdealClient`, label: 'Not Ideal Client' },
                                { value: `${id}_declined`, label: 'Declined' },
                                { value: `${id}_stoppedReplying`, label: 'Stopped Replying' },
                                { value: `${id}_neverReplyed`, label: 'Never Replied' }
                            ]} />
                    }
                </>
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            //responsive: ['md'],
            ...getColumnSearchProps('email'),
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            key: 'phone',
            //responsive: ['md'],
            ...getColumnSearchProps('phone'),
        },
        {
            title: 'Socail Media',
            dataIndex: 'socialMedia',
            key: 'socialMedia',
            //responsive: ['md'],
            ...getColumnSearchProps('socialMedia'),
        },
        {
            title: 'Date',
            dataIndex: 'createDate',
            key: 'createDate',
            //responsive: ['md'],
        },
        {
            title: 'Lead Info',
            key: 'tags',
            dataIndex: 'tags',
            filters: [
                { text: 'Completed Application', value: 'completed' },
                { text: 'Partial Application', value: 'partial' },
            ],
            filteredValue: filteredInfo.isComplete,
            onFilter: (value, record) => {
                return value === 'completed' && record.isComplete || value === 'partial' && !record.isComplete
            },
            //ellipsis: true,
            //responsive: ['md'],
            render: (_, { isComplete, howHearAboutUs }) => (
                <>
                    {isComplete && <><Tag color={'green'}>Completed Application</Tag><Tag color={'gold'}>{howHearAboutUs}</Tag></>}
                    {!isComplete && <><Tag color={'geekblue'}>Partial Application</Tag><Tag color={'gold'}>{howHearAboutUs ?? 'unknown'}</Tag></>}
                </>
            ),
        }
    ];

    const handleStatusChange = async (value: string) => {

        let parts: Array<string> = value.split('_');
        if (parts?.length === 2) {
            let applicationResponse = await ApplicationService.updateStatus(parseInt(parts[0]), parts[1]);
            if (applicationResponse.success) {
                messageApi.open({
                    type: 'success',
                    content: 'The applications status was updated'
                });
            } else {
                messageApi.open({
                    type: 'error',
                    content: 'Sorry, we were unable to update the application status'
                });
            }
        }
    }

    const markApplicationAsClosdedDeal = async () => {
        let applicationTableDataCopy = [...applicationTableData];

        let found = _.find(applicationTableDataCopy, { id: selectedApplicationRef.current?.id });

        if (found) {
            found.status = _closedDealStatus;
            setApplicationTableData(applicationTableDataCopy);

            let foundApp = _.find(applicationsRef.current, { id: found.id });
            if (foundApp) {
                foundApp.status = _closedDealStatus;
            }

            //todo: make api call to update status to closed and set closedDealDate
            setIsClosedDealModalOpen(false);
            setIsDrawerOpen(false);
            let applicationResponse = await ApplicationService.updateStatus(found.id, _closedDealStatus);
            messageApi.open({
                type: 'success',
                content: 'Great Job! You have successfully marked this application as a closed deal!'
            });
        }
    }

    const onInstaDmLeadChanged: CheckboxProps['onChange'] = async (e) => {
        let applicationData = selectedApplicationRef.current;
        const INSTA_DM:string = 'insta DM';
        const HOW_HEAR_ABOUT_US_PROP_NAME = 'howDidYouHearAboutUs';

        if (e.target.checked && applicationData?.id && applicationData?.response) {
            applicationData.response[HOW_HEAR_ABOUT_US_PROP_NAME] = INSTA_DM;
            let response = await ApplicationService.updateResponse(applicationData.id, applicationData.response);
            if (!response.success) {
                messageApi.open({
                    type: 'error',
                    content: 'Sorry, we were unable to update the note at this time'
                });
            } else {
    
                let foundApp = _.find(applicationsRef.current, { id: selectedApplicationRef.current?.id });
                if (foundApp) {
                    foundApp[HOW_HEAR_ABOUT_US_PROP_NAME] = INSTA_DM;
                }
            }    
        }
    }

    const onNoteEdited = async (value: string) => {
        let response = await ApplicationService.updateNotes(selectedApplicationRef.current?.id, value);
        if (!response.success) {
            messageApi.open({
                type: 'error',
                content: 'Sorry, we were unable to update the note at this time'
            });
        } else {

            let foundApp = _.find(applicationsRef.current, { id: selectedApplicationRef.current?.id });
            if (foundApp) {
                foundApp.notes = value;
            }

            setApplicationNotes(value);
        }
    }

    return (
        <div className="applications">
            {contextHolder}
            <h1>Applications</h1>
            <Table dataSource={applicationTableData} columns={columns} onChange={handleChange} scroll={{ x: 1300 }} bordered />
            <Drawer
                title="User Application"
                height={'90%'}
                placement={'bottom'}
                onClose={() => setIsDrawerOpen(false)}
                open={isDrawerOpen}
                closable={false}
                extra={<Flex>
                    {selectedApplicationRef.current?.status === _closedDealStatus && <Tag color={'green'} style={{ marginRight: 10 }}>Closed Deal</Tag>}
                    {selectedApplicationRef.current?.status !== _closedDealStatus && <Button type="primary" style={{ marginRight: 20 }} onClick={() => setIsClosedDealModalOpen(true)}>Mark as deal closed</Button>}
                    <Checkbox onChange={onInstaDmLeadChanged}>Is Insta DM Lead</Checkbox>
                    <CloseOutlined onClick={() => setIsDrawerOpen(false)} />
                </Flex>}
            >
                <div>
                    {(!isAddingNote && !selectedApplicationRef.current?.notes) && <>
                        <div style={{ float: 'right' }}>
                            <Button style={{ marginBottom: 10 }} onClick={() => setIsAddingNote(true)}>Add a note to this application</Button>
                        </div>
                        <div style={{ clear: 'both' }}>
                        </div>
                    </>}
                    {(!isAddingNote && selectedApplicationRef.current?.notes) && <>
                        <Card style={{ marginBottom: 20 }} title="Application Notes">
                            <Paragraph editable={{
                                tooltip: 'click to edit the note',
                                onChange: onNoteEdited,
                            }}>
                                {applicationNotes}
                            </Paragraph>
                        </Card>
                    </>}
                    {isAddingNote && <>
                        <h2 style={{ alignSelf: 'flex-end' }}>Add a note to this application</h2>
                        <TextArea rows={4} value={applicationNotes} onChange={(e) => setApplicationNotes(e.target.value)} />
                        <Space style={{ marginTop: 20, marginBottom: 20 }}>
                            <Button onClick={() => setIsAddingNote(false)}>Cancel</Button>
                            <Button type="primary" onClick={async () => {
                                if (applicationNotes) {
                                    let response = await ApplicationService.updateNotes(selectedApplicationRef.current.id, applicationNotes);
                                    if (!response.success) {
                                        messageApi.open({
                                            type: 'error',
                                            content: 'Sorry, we were unable to save your note. Please try again'
                                        });
                                    } else {
                                        selectedApplicationRef.current.notes = applicationNotes;
                                        setIsAddingNote(false);
                                        messageApi.open({
                                            type: 'success',
                                            content: 'Successfully updated the note'
                                        });
                                    }
                                }
                            }}>Save Note</Button>
                        </Space>
                    </>}
                    <List
                        itemLayout="horizontal"
                        dataSource={applicationDetails}
                        renderItem={(item, index) => (
                            <List.Item>
                                <List.Item.Meta
                                    title={item.title}
                                    description={item.description}
                                />
                            </List.Item>
                        )}
                    />
                </div>
            </Drawer>
            <Modal
                title="Closed Deal?"
                open={isClosedDealModalOpen}
                onOk={markApplicationAsClosdedDeal}
                onCancel={() => setIsClosedDealModalOpen(false)}
                okText="Mark as Closed"
            >
                Update this application as a closed deal? Note, you should only do this once we have a signed contract and received the first payment.
            </Modal>
        </div>
    )
}

import axios from "axios";
import { getAuth } from "firebase/auth";
import moment from "moment";
import { CoachingProgram } from "../types/CoreTypes";
import { SimpleResponse } from "../types/DTOs";
import Constants from "./Constants";

export type CoachingProgramInviteUserRequestData = {
    plan: string,
    type: string,
    firstName: string,
    lastName: string,
    email: string,
    numMonths: number,
    coachUid: string,
    pricePerMonth: number,
    priceInFull: number,
    isPaidInFull: boolean,
    programStartDate: string,
    inviteId?: number
}

interface ICoachingProgramService {
    completePendingInvite: () => Promise<SimpleResponse>;
    getAllProgramUsers: () => Promise<any>;
    getCurrentWeekOfCoachingProgram: (coachingProgram:CoachingProgram) => number;
    getPendingProgramInvite: (inviteId:number) => Promise<SimpleResponse>;
    getProgramByUid: (uid: string) => Promise<any>;
    inviteUser: (request:CoachingProgramInviteUserRequestData) => Promise<any>;
    resendUserInvite: (email: string, inviteId: string) => Promise<any>;
}

class CoachingProgramService implements ICoachingProgramService {
    completePendingInvite = async () => {
        const auth = getAuth();
        let idToken = await auth.currentUser?.getIdToken(true);

        try {
            if (!idToken) throw new Error("invalid token");
            let res = await axios({
                url: `${Constants.apiBaseUrl}/coachingProgram/completeInvite`, 
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${idToken}`
                }
            });

            return { success: true };
        } catch (err) {
            return { success: false };
        }
    };

    getAllProgramUsers = async () => {    
        const auth = getAuth();
        let idToken = await auth.currentUser?.getIdToken(true);

        try {
            if (!idToken) throw new Error("invalid token");
            let res = await axios({
                url: `${Constants.apiBaseUrl}/coachingProgram/allProgramUsers`, 
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${idToken}`
                }
            });

            let dataToReturn = res.data;

            return dataToReturn;
        } catch (err) {
            console.error(err);
            return false;
        }
    };

    getCurrentWeekOfCoachingProgram = (coachingProgram:CoachingProgram) => {
        let today = moment().startOf('day');

        let sDate = moment(coachingProgram.startDateTime);

        let eDate = moment(coachingProgram.endDateTime);

        if (today.isAfter(eDate)) {
            //program has already ended, so we need to send total weeks
            let diffInWks = eDate.diff(sDate, 'week');
            return diffInWks;
        }

        let diffInWks = today.diff(sDate, 'week');

        let currWeek = diffInWks + 1;

        return currWeek;
    };

    getProgramByUid = async (uid: string) => {
        if (!uid) return false;

        const auth = getAuth();
        let idToken = await auth.currentUser?.getIdToken(true);

        let url = `${Constants.apiBaseUrl}/coachingProgram/byUid/${uid}`;

        try {
            if (!idToken) throw new Error("invalid token");
            let res = await axios({
                url, 
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${idToken}`
                }
            });

            let dataToReturn = res.data;

            return dataToReturn;
        } catch (err) {
            return false;
        }
    }

    getPendingProgramInvite = async (inviteId:number) => {
        if (!inviteId || !Number(inviteId) || !Number.isInteger(Number(inviteId))) return { success: false };

        try {
            let res:any = await axios({
                url: `${Constants.apiBaseUrl}/coachingProgram/userInvite/${inviteId}`, 
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                }
            });
            //if no errors and we get a status of 200, return true

            if (res && res.data?.msg === 'success') return { success: true };
            else return { success: false };
        } catch (err) {
            return { success: false };
        }
    };

    inviteUser = async (requestData:CoachingProgramInviteUserRequestData, isUpdatingEmail:boolean=false) => {    
        if (isUpdatingEmail && (!requestData || !requestData.inviteId || !requestData.email)) {
            return false;
        }

        if (!isUpdatingEmail && (!requestData || !requestData.email || !requestData.numMonths)) return false;

        const auth = getAuth();
        let idToken = await auth.currentUser?.getIdToken(true);

        try {
            if (!idToken) throw new Error("invalid token");
            let res = await axios({
                url: `${Constants.apiBaseUrl}/coachingProgram/inviteUser`, 
                method: 'POST',
                data: requestData,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${idToken}`
                }
            });

            let dataToReturn = res.data;

            return dataToReturn;
        } catch (err) {
            return false;
        }
    };

    resendUserInvite = async (email: string, inviteId: string) => {    
        if (!email || !inviteId) return;

        const auth = getAuth();
        let idToken = await auth.currentUser?.getIdToken(true);

        try {
            if (!idToken) throw new Error("invalid token");
            let res = await axios({
                url: `${Constants.apiBaseUrl}/coachingProgram/resendUserInvite`, 
                method: 'POST',
                data: { email, inviteId },
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${idToken}`
                }
            });

            let dataToReturn = res.data;

            return dataToReturn;
        } catch (err) {
            return false;
        }
    };
}

export {
    type ICoachingProgramService,
    CoachingProgramService
}
import { FireFilled } from "@ant-design/icons";
import { Card, Flex, Typography } from "antd";
import _ from "lodash";
import { useEffect, useState } from "react";
import CommonDataService from "../services/CommonDataService";
import { LogDayExerciseEntry } from "../types/LogTypes";

export type LogDayExerciseProps = {
    logDayExercises: Array<LogDayExerciseEntry>,
    // onViewNutritionInfoClicked: (meal: Meal) => void;
};

export default function LogDayExercise({ logDayExercises }: LogDayExerciseProps) {
    const [detailedExerciseInfo, setDetailedExerciseInfo] = useState<Array<LogDayExerciseEntry>>([]);
    const [totalCals, setTotalCals] = useState<number>(0);
    const [totalMins, setTotalMins] = useState<number>(0);

    const { Text } = Typography;
    
    useEffect(() => {
        let allExercises = CommonDataService.getAllExercises();
        let detailedExercises:Array<LogDayExerciseEntry> = [];

        let sumCals:number = 0;
        let sumMins:number = 0;
        _.forEach(logDayExercises, (logDayExercise) => {
            let found = _.find(allExercises, { id: logDayExercise.id });
            if (found) {
                logDayExercise.title = found.title;
                logDayExercise.subtitle = found.subtitle;
                sumCals += parseInt(logDayExercise.cals as string);
                sumMins += logDayExercise.mins;
                detailedExercises.push({
                    cals: logDayExercise.cals,
                    id: logDayExercise.id,
                    mins: logDayExercise.mins,
                    subtitle: found.subtitle,
                    title: found.title
                });
            }
        });
        setTotalCals(sumCals);
        setTotalMins(sumMins);

        setDetailedExerciseInfo(detailedExercises);
    }, [logDayExercises]);

    return (
        <div className="logDayExercise">
            <Card 
                title="Exercises"
                extra={ (totalCals > 0 || totalMins > 0) ? <Flex justify={'flex-end'}>
                <b style={{ marginRight: 5, color: CommonDataService.getAppColors().orange }}>{ totalCals }<FireFilled /></b>
                <b style={{ marginRight: 5, color: CommonDataService.getAppColors().green }}>{ totalMins } Minutes</b>
            </Flex> : <></>}
                style={{marginTop: 20}}>
                {
                    detailedExerciseInfo &&
                    detailedExerciseInfo.map((exercise: LogDayExerciseEntry, exerciseIndex: any) => (
                        <>
                            <Text ellipsis>
                                <b>{exercise.title}</b>
                                {exercise.subtitle && <span>, {exercise.subtitle}</span>}
                            </Text>
                            <Flex justify={'flex-end'}>
                                <b style={{ marginRight: 5, color: CommonDataService.getAppColors().orange }}>{ exercise.cals }<FireFilled /></b>
                                <b style={{ marginRight: 5, color: CommonDataService.getAppColors().green }}>{ exercise.mins } Minutes</b>
                            </Flex>
                        </>
                    ))
                }
                {detailedExerciseInfo?.length <= 0 && <span>No exercises logged yet. Start Logging</span>}
            </Card>
        </div>
    )
};
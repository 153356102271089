import { useEffect, useState } from "react";
import LogDayService from "../services/LogDayService";
import { useLocation } from 'react-router-dom';
import moment, { Moment } from 'moment';
import _ from "lodash";
import { CompressedServingInfo, LogDay, Meal, MealEntry } from "../types/LogTypes";
import CommonDataService from "../services/CommonDataService";
import { BasicBarChart, BasicBarChartData, BasicBarChartProps } from "../components/BasicBarChart";
import WeightLossGoalService from "../services/WeightLossGoalService";
import { WeightLossGoal } from "../types/GoalTypes";
import { Card, Col, Flex, Radio, RadioChangeEvent, Row, DatePicker, TimeRangePickerProps, Button, Drawer } from 'antd';
import NutritionInfo from "../components/NutritionInfo";
import LogDayMeals from "../components/LogDayMeals";
import dayjs, { Dayjs } from "dayjs";
import { LogDayWeight, LogDayWeightProps } from "../components/LogDayWeight";
import UserService from "../services/UserService";
import { User } from "../types/CoreTypes";
import LogDayExercise from "../components/LogDayExercise";
import NotesDrawer from "../components/Notes/NotesDrawer";

export default function UserLog() {
    //const [userTableData, setUserTableData] = useState<any>(undefined);
    const [chartProps, setChartProps] = useState<BasicBarChartProps>();
    const [currWtLossGoal, setCurrWtLossGoal] = useState<WeightLossGoal>();
    const [userLogDays, setUserLogDays] = useState<Array<LogDay>>();
    const [selectedLogDay, setSelectedLogDay] = useState<LogDay>();
    const [nutritionInfo, setNutritionInfo] = useState<CompressedServingInfo>();
    const [nutritionInfoAverages, setNutritionInfoAverages] = useState<CompressedServingInfo>();
    const [currMacroMacroDisplay, setCurrMacroMacroDisplay] = useState<string>();
    const [selectedMeal, setSelectedMeal] = useState<Meal>();
    const [logDayWeightProps, setLogDayWeightProps] = useState<LogDayWeightProps>();
    const [isNotesDrawerOpen, setIsNotesDrawerOpen] = useState<boolean>(false);

    const { RangePicker } = DatePicker;
    const location = useLocation();
    const { user } = location.state;

    const loadLogDays = async (startDate: Moment, endDate: Moment) => {
        let formattedStartDate: string = startDate.format('YYYY-MM-DD');
        let formattedEndDate: string = endDate.format('YYYY-MM-DD');
        let daysDiff: number = endDate.diff(startDate, 'days');

        let logDayResponse = await LogDayService.getLogDaysByDateRange(formattedStartDate, formattedEndDate, user.uid);

        let currUser = CommonDataService.getCurrentUser();

        let logDays: Array<LogDay> = [];
        if (!logDayResponse.success) {
            //return out with empty set of logDays
            for (let i = 0; i < daysDiff; i++) {
                let dateToUse = moment(formattedEndDate).subtract(i, 'd').format('YYYY-MM-DD');

                let emptyLogDay: LogDay = {
                    //id: -1,
                    uid: currUser.uid,
                    date: dateToUse,
                    modifiedDate: null,
                    log: LogDayService.getEmptyLogDay(dateToUse)
                }

                logDays.push(emptyLogDay);
            }
            return logDays;
        }


        for (let i = 0; i <= daysDiff; i++) {
            let dateToUse = moment(formattedEndDate).subtract(i, 'd').format('YYYY-MM-DD');
            let found: LogDay | undefined = _.find(logDayResponse.logDays, { date: dateToUse });
            if (!found) {
                let emptyLogDay: LogDay = {
                    //id: -1,
                    uid: currUser?.uid,
                    date: dateToUse,
                    modifiedDate: null,
                    log: LogDayService.getEmptyLogDay(dateToUse)
                }

                logDays.push(emptyLogDay);
            } else {
                _.forEach(found.log.meals, (meal: Meal) => {
                    _.forEach(meal.entries, (mealEntry: MealEntry, mealEntryIndex: number) => {
                        if (mealEntry.foodType === 'quickEntry' && mealEntry.servingInfo) {
                            // @ts-ignore
                            meal.entries[mealEntryIndex]['servings'] = [{
                                fat: mealEntry.servingInfo.fat,
                                pro: mealEntry.servingInfo.protein,
                                cals: mealEntry.servingInfo.calories,
                                carb: mealEntry.servingInfo.carbohydrate
                            }];
                            // @ts-ignore
                            meal.entries[mealEntryIndex].loggedCalculatedServing = LogDayService.roundNumbers(meal.entries[mealEntryIndex]['servings'][0], parseFloat(mealEntry.numServings));
                        } else {
                            let foundServing = _.find(mealEntry.servings, { sId: mealEntry.servingId });
                            if (foundServing) {
                                // @ts-ignore
                                meal.entries[mealEntryIndex].loggedCalculatedServing = LogDayService.roundNumbers(foundServing, parseFloat(mealEntry.numServings));
                            }
                        }
                    });
                });
                logDays.push(found);
            }
        }

        let dayMacroTotalsList: Array<CompressedServingInfo> = [];

        _.forEach(logDayResponse.logDays, (logDay) => { dayMacroTotalsList.push(LogDayService.getNutritionTotalsForLogDayMeals(logDay.log.meals)); });

        let nutritionInfoAverages = LogDayService.averageOutLogDayTotals(dayMacroTotalsList);
        setNutritionInfo(nutritionInfoAverages);
        setNutritionInfoAverages(nutritionInfoAverages);
        setSelectedMeal(undefined);

        return logDays;
    }

    //todo: figure out why sometimes userLogDays is not set. We will likely need to use a context to share data between components
    const barChartBarClicked = (data: any) => {
        if (data.logDay) setSelectedLogDay(data.logDay);

        let dayMacroTotalsList: Array<CompressedServingInfo> = [];
        _.forEach([data.logDay], (logDay) => { dayMacroTotalsList.push(LogDayService.getNutritionTotalsForLogDayMeals(logDay.log.meals)); });
        let nutritionInfoAverages = LogDayService.averageOutLogDayTotals(dayMacroTotalsList);
        setNutritionInfo(nutritionInfoAverages);
        setSelectedMeal(undefined);

        window.scrollTo(0, 0);
    }

    const loadWeightLossGoal = async (logDays: Array<LogDay>) => {
        let weightLossGoalResponse = await WeightLossGoalService.getWeightLossGoals(user.uid);

        if (weightLossGoalResponse?.success && weightLossGoalResponse.weightLossGoals && weightLossGoalResponse.weightLossGoals.length > 0) {
            let wtLossGoal = weightLossGoalResponse.weightLossGoals[0];
            setCurrWtLossGoal(wtLossGoal);

            let barChartData: Array<BasicBarChartData> = buildBarChartDataFromLogDays(logDays);

            setChartProps({
                width: '100%',
                height: 300,
                chartColor: CommonDataService.getAppColors().orange,
                referenceLineValue: wtLossGoal.targetCals,
                chartData: barChartData,
                onBarChartClicked: barChartBarClicked
            });
        }
    }

    const buildBarChartDataFromLogDays = (logDays: Array<LogDay>, type: string | null = null): Array<BasicBarChartData> => {
        let barChartData: Array<BasicBarChartData> = [];
        let dayMacroTotalsList: Array<CompressedServingInfo> = [];

        _.forEach(logDays, (logDay, i: number) => {
            let dayMacroTotals = LogDayService.getNutritionTotalsForLogDayMeals(logDay.log.meals);
            dayMacroTotalsList.push(dayMacroTotals);

            let val = dayMacroTotals.cals;
            if (type === 'carbs') val = dayMacroTotals.carb;
            else if (type === 'protein') val = dayMacroTotals.pro;
            else if (type === 'fats') val = dayMacroTotals.fat;

            barChartData.unshift({ value: val as number, xAxisLabel: moment(logDay.date).format('MM/DD') as string, logDay })
        })

        return barChartData;
    }

    const loadData = async () => {
        let endDate = moment();
        let startDate = moment().add(-13, 'days');
        //defaulting to past 14 days
        let logDays = await loadLogDays(startDate, endDate);
        setUserLogDays(logDays);

        let userResponse = await UserService.getUserFromApi(user.uid);
        if (userResponse.user) CommonDataService.setCurrentLogDayUser(userResponse.user);

        setLogDayWeightProps({ user: userResponse.user as User, startDate, endDate });

        await loadWeightLossGoal(logDays);
    }

    const onDayWeekToggleChange = (e: RadioChangeEvent) => {
        if (e.target.value === 'week') {
            setSelectedLogDay(undefined);
            setSelectedMeal(undefined);
            setNutritionInfo(nutritionInfoAverages);
        }
    }

    const onChartMacroDisplayChange = (e: RadioChangeEvent) => {
        if (userLogDays) {
            setCurrMacroMacroDisplay(e.target.value);
            let barChartData: Array<BasicBarChartData> = buildBarChartDataFromLogDays(userLogDays, e.target.value);

            if (chartProps) {
                if (e.target.value === 'cals') {
                    chartProps.chartColor = CommonDataService.getAppColors().orange;
                    chartProps.referenceLineValue = currWtLossGoal?.targetCals as number;
                }
                else if (e.target.value === 'protein') {
                    chartProps.chartColor = CommonDataService.getAppColors().green;
                    chartProps.referenceLineValue = currWtLossGoal?.targetProtein as number;
                }
                else if (e.target.value === 'carbs') {
                    chartProps.chartColor = CommonDataService.getAppColors().blue;
                    chartProps.referenceLineValue = currWtLossGoal?.targetCarbs as number;
                }
                else if (e.target.value === 'fats') {
                    chartProps.chartColor = CommonDataService.getAppColors().red;
                    chartProps.referenceLineValue = currWtLossGoal?.targetFats as number;
                }
                chartProps.chartData = barChartData;

                setChartProps({ ...chartProps });
            }
        }
    };

    const onRangeChange = async (dates: null | (Dayjs | null)[], dateStrings: string[]) => {
        if (dates) {
            let logDays = await loadLogDays(moment(dateStrings[0], 'YYYY/MM/DD'), moment(dateStrings[1], 'YYYY/MM/DD'));
            setUserLogDays(logDays);
            await loadWeightLossGoal(logDays);
            setSelectedLogDay(undefined);
            setLogDayWeightProps({ user: CommonDataService.getCurrentLogDayUser() as User, startDate: moment(dateStrings[0], 'YYYY/MM/DD'), endDate: moment(dateStrings[1], 'YYYY/MM/DD') });
            setCurrMacroMacroDisplay('cals');
        } else {
            console.log('Clear');
        }
    };

    const rangePresets: TimeRangePickerProps['presets'] = [
        { label: 'Last 7 Days', value: [dayjs().add(-6, 'd'), dayjs()] },
        { label: 'Last 14 Days', value: [dayjs().add(-13, 'd'), dayjs()] },
        { label: 'Last 30 Days', value: [dayjs().add(-29, 'd'), dayjs()] },
        { label: 'Last 90 Days', value: [dayjs().add(-89, 'd'), dayjs()] },
    ];

    const disabledDate = (current: Dayjs) => {
        return current.isAfter(dayjs());
    };

    const getNutritionInfoTitle = () => {
        let title = '';
        if (!selectedLogDay) {
            title = '(as averages)';
        } else {
            if (selectedMeal) {
                title += `${selectedMeal.name}: `;
            }
            title += `${moment(selectedLogDay.date).format('dddd, MMM DD')}`
        }
        return title;
    }

    const onMealFullNutritionInfoClicked = (meal: Meal) => {
        setNutritionInfo(LogDayService.getNutritionTotalsForLogDayMeals([meal]));
        setSelectedMeal(meal);
        window.scrollTo(0, 0);
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        loadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="userLog">
            <Flex justify={'space-between'} align={'center'}>
                <h1>{user.name}</h1>
                <Button type="primary" onClick={() => setIsNotesDrawerOpen(!isNotesDrawerOpen)}>Notes</Button>
            </Flex>
            {selectedLogDay &&
                <Flex justify={"center"} style={{ marginBottom: 20 }}>
                    <Radio.Group defaultValue="day" onChange={onDayWeekToggleChange} buttonStyle="solid">
                        <Radio.Button value="day">Viewing {moment(selectedLogDay.date).format('dddd, MMM DD')}</Radio.Button>
                        <Radio.Button value="week">View Averages For All Days</Radio.Button>
                    </Radio.Group>
                </Flex>}
            <Row>
                <Col xs={{span: 24, order: 2}} md={{span: 12, order: 1}} lg={{span: 16}} style={{ paddingRight: 20 }}>
                    {chartProps &&
                        <Card size="small">
                            <Flex justify={"center"}>
                                <RangePicker
                                    presets={[...rangePresets]}
                                    disabledDate={disabledDate}
                                    format="YYYY/MM/DD"
                                    onChange={onRangeChange}
                                />
                            </Flex>
                            <BasicBarChart {...chartProps} />
                            <Flex justify={"center"}>
                                <Radio.Group defaultValue="cals" value={currMacroMacroDisplay} onChange={onChartMacroDisplayChange} buttonStyle="solid">
                                    <Radio.Button value="cals">Calories</Radio.Button>
                                    <Radio.Button value="protein">Protein</Radio.Button>
                                    <Radio.Button value="carbs">Carbs</Radio.Button>
                                    <Radio.Button value="fats">Fats</Radio.Button>
                                </Radio.Group>
                            </Flex>
                        </Card>}
                    {!selectedLogDay &&
                        <Card style={{ marginTop: 20 }}>
                            <Flex justify={'center'}>
                                <h4>Currently viewing averages. You can drill down by clicking a day in the chart above</h4>
                            </Flex>
                        </Card>}
                    {selectedLogDay &&
                        <div>
                            <LogDayMeals meals={selectedLogDay.log.meals} onViewNutritionInfoClicked={onMealFullNutritionInfoClicked} />
                        </div>}
                    <LogDayWeight {...logDayWeightProps as LogDayWeightProps} />
                    {selectedLogDay &&
                        <LogDayExercise logDayExercises={selectedLogDay?.log.exercises} />}
                </Col>
                <Col xs={{span: 24, order: 1}} md={{span: 12, order: 2}} lg={{span: 8}}>
                    {nutritionInfo && <NutritionInfo serving={nutritionInfo as CompressedServingInfo} extraTitle={getNutritionInfoTitle()} />}
                </Col>
            </Row>
            <NotesDrawer isOpen={isNotesDrawerOpen} closeDrawer={() => setIsNotesDrawerOpen(false)} />
        </div>
    )
}
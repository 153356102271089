// @ts-nocheck
import { useEffect } from "react"
import { Link } from "react-router-dom"
//import { Helmet } from 'react-helmet'
//import { Link, useParams } from "react-router-dom"

export default function TermsOfService() {

    // const { slug } = useParams();
    // const { uuid } = usePolicyConfig(slug);



//     <div name="termly-embed" data-id="84202eee-dd3d-4bb5-b110-afb471c54474" data-type="iframe"></div>
// <script type="text/javascript">(function(d, s, id) {
//   var js, tjs = d.getElementsByTagName(s)[0];
//   if (d.getElementById(id)) return;
//   js = d.createElement(s); js.id = id;
//   js.src = "https://app.termly.io/embed-policy.min.js";
//   tjs.parentNode.insertBefore(js, tjs);
// }(document, 'script', 'termly-jssdk'));</script>


    useEffect(() => {
        window.scrollTo(0, 0);

        const loadPolicy = async () => {
            try {
                const script = document.createElement("script");
                script.src = "https://app.termly.io/embed-policy.min.js";
                script.async = true;
                await document.body.appendChild(script);
            } catch (e) {
                console.error('error loading script: ', e);
            }
        }

        loadPolicy();
    }, []);

    return(
        <>
            <div className="terms-service">
                
                <div className="close"><Link to={"/"}>Close</Link></div>

                
                <div name="termly-embed" data-id="84202eee-dd3d-4bb5-b110-afb471c54474" data-type="iframe"></div>

                {/* <Helmet>
                    <script
                    type="text/javascript"
                    src={ SCRIPT_SRC }
                    />
                </Helmet>

                <div
                    data-id={ uuid }
                    data-type="iframe"
                    name="termly-embed"
                /> */}
            </div>
        </>

    )
}
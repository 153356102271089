import axios from "axios";
import { getAuth } from "firebase/auth";
import { User } from "../types/CoreTypes";
import { UserResponse } from "../types/DTOs";
import Constants from "./Constants";


interface IUserService {
    getFullName: (firstName: string, lastName: string) => string;
    getUserFromApi: (uid?:string) => Promise<UserResponse>;
    usesStandardWeight: (user: User) => Boolean;
    usesStandardHeight: (user: User) => Boolean;
}

class UserService implements IUserService {
    getFullName = (firstName: string, lastName: string) => {
        let name = '';
        
        if (firstName && lastName) name = `${firstName} ${lastName}`;
        else if (lastName) name = `${lastName}`;
        else if (firstName) name = `${firstName}`;

        return name;
    };
    getUserFromApi = async(uid?:string):Promise<UserResponse> => {
        const auth = getAuth();
        let idToken = await auth.currentUser?.getIdToken(true);
    
        let url = `${Constants.apiBaseUrl}/user`;
        if (uid) url += `/${uid}`; //in this scenario we will pull a uid from another user than ourself
        try {
            if (!idToken) throw new Error("invalid token");

            let res = await axios({
                url, 
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${idToken}`
                }
            });

            if (res.status === 206) { //handle scnario of partial user (user that created account with firebase, but didnt complete singup)
                return { success: true, isPartialUser: true };
            }

            if (res.data) {
                if (res.data.heightCm) res.data.heightCm = parseFloat(res.data.heightCm);
                if (res.data.heightMetric) res.data.heightMetric = parseFloat(res.data.heightMetric);
                if (res.data.weightMetric) res.data.weightMetric = parseFloat(res.data.weightMetric);
            }

            return { success: true, user: res.data as User };
        } catch (err) {
            //could alert there was error or that they are in offline mode
            return { success: false };
        }
    }
    usesStandardWeight = (user: User) => {
        // @ts-ignore
        return parseInt(user.weightMetric) === 0;
    };
    usesStandardHeight = (user: User) => {
        // @ts-ignore
        return parseInt(user.heightMetric) === 0;
    };
}

export default new UserService() as IUserService
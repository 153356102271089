import { Button, Card, Divider, Flex, Form, InputNumber, message, Radio, RadioChangeEvent, Space, Typography } from "antd";
import moment from "moment";
import { useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";
import { ProgramSignupContext } from "../hooks/ContextHooks";
import { biometricService, ValidationResult } from "../services/BiometricService";
import { User } from "../types/CoreTypes";
import { WeightForApi, WeightLossGoalForApi } from "../types/DTOs";
import { auth } from '../firebase';
import { useAuthState } from "react-firebase-hooks/auth";
import WeightLossGoalService from "../services/WeightLossGoalService";
import { CoachingProgramService } from "../services/CoachingProgramService";

export default function WeightLossGoalTargets() {
    //const [currStep, setCurrStep] = useState<number>(1);
    const [targetWeightLbs, setTargetWeightLbs] = useState<string>();
    const [targetWeightKg, setTargetWeightKg] = useState<string>();
    const [weightDisplay, setWeightDisplay] = useState<string>();
    const [targetWeight, setTargetWeight] = useState<string>();
    const [weightErrMsg, setWeightErrMsg] = useState<string>();
    const [isStandardWt, setIsStandardWt] = useState<boolean>(true);
    const [selectedActivityLevel, setSelectedActivityLevel] = useState<number>();
    const [hasAttemptedSubmit, setHasAttemptedSubmit] = useState<boolean>(false);
    const [hasPreviouslySetGoals, setHasPreviouslySetGoals] = useState<boolean>(false);
    const [proteinPercent, setProteinPercent] = useState<number>(30);
    const [carbsPercent, setCarbsPercent] = useState<number>(40);
    const [fatsPercent, setFatsPercent] = useState<number>(30);
    const [authenticatedUser, loading] = useAuthState(auth);

    const [messageApi, contextHolder] = message.useMessage();

    const navigate = useNavigate();

    const { state, update }:any = useContext(ProgramSignupContext);

    const user:User = state.user;
    const weight:WeightForApi = state.weight;
    const weightLossGoal:WeightLossGoalForApi = state.weightLossGoal;

    useEffect(() => {
        const handleBeforeUnload = (event: any) => {
            event.preventDefault();
            // Custom logic to handle the refresh
        };
        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    useEffect(() => {
        if (!user.firstName && !user.lastName) navigate("/programSignup/1");

        let isStandard = user.weightMetric === biometricService.weightPounds;
        if (user && weight) {
            setIsStandardWt(isStandard);
            setWeightDisplay(isStandard ? `${weight.weightLbs.toString()} lbs` : `${weight.weightKg.toString()} kg`);
        }
        if (weightLossGoal) {
            setTargetWeight((isStandard ? weightLossGoal.goalWeightLbs : weightLossGoal.goalWeightKg).toString());
            setSelectedActivityLevel(weightLossGoal.activityFactor);
            setTargetWeightLbs(weightLossGoal.goalWeightLbs.toString());
            setTargetWeightKg(weightLossGoal.goalWeightKg.toString());
        }
    }, [user, weight]);

    useEffect(() => {
        if (!hasAttemptedSubmit) return;

        validateForm();        
    }, [weightDisplay, targetWeightLbs, targetWeightKg, hasAttemptedSubmit]);

    const validateForm = async (fromSubmit:boolean=false) => {
        let weightErr:string | undefined = undefined;
        let wtValidationResult:ValidationResult = biometricService.validateWeight(targetWeightLbs?.toString() as string, targetWeightKg?.toString() as string, biometricService.weightPounds);
        if (!wtValidationResult.isValid) weightErr = wtValidationResult.error;
        else weightErr = undefined;
        setWeightErrMsg(weightErr);

        if (fromSubmit) {
            if (!weightErr && selectedActivityLevel) {
                let targetCals = calculateTargetCalories(Number(selectedActivityLevel));

                if (!targetCals) return; //todo: show error message

                // if (hasPreviouslySetGoals) {
                //     targetCals = targetCalories;
                // }

                let targetProtein = Math.round((targetCals * (proteinPercent/100)) / 4);
                let targetCarbs = Math.round((targetCals * (carbsPercent/100)) / 4);
                let targetFats = Math.round((targetCals * (fatsPercent/100)) / 9);

                let numWeeksToMeetGoal;
                //default to 1lb per week
                if (Number(targetWeightLbs) > weight.weightLbs) {
                    //desire weight gain
                    numWeeksToMeetGoal = Math.round(Number(targetWeightLbs)) - Math.round(weight.weightLbs);
                } else if (weight.weightLbs > Number(targetWeightLbs)) {
                    numWeeksToMeetGoal = Math.round(weight.weightLbs) - Math.round(Number(targetWeightLbs));
                } else { //values are equal, no wt loss or gain desired
                    numWeeksToMeetGoal = 0;
                }

                let endDate = moment().add(numWeeksToMeetGoal, 'weeks').format('YYYY-MM-DD');

                if (!authenticatedUser || !authenticatedUser.email) {
                    navigate("/login");
                }

                let wtLossGoal: WeightLossGoalForApi = {
                    startDate: moment().format('YYYY-MM-DD'),
                    endDate,
                    // startWeightLbs: !hasPreviouslySetGoals ? weight.weightLbs : startWtLbs,
                    // startWeightKg: !hasPreviouslySetGoals ? weight.weightKg : startWtKg,
                    startWeightLbs: weight.weightLbs,
                    startWeightKg: weight.weightKg,
                    goalWeightLbs: Number(targetWeightLbs),
                    goalWeightKg: Number(targetWeightKg),
                    targetCals,
                    targetProtein,
                    targetCarbs,
                    targetFats,
                    proteinPercent,
                    carbsPercent,
                    fatsPercent,
                    activityFactor: selectedActivityLevel
                };

                let usr = state.user;
                usr.email = authenticatedUser?.email;

                update({ weightLossGoal: wtLossGoal, user: usr });

                let userRes = await biometricService.syncUserWithApi(usr);
                if (!userRes.success) {
                    messageApi.open({
                        type: 'error',
                        content: 'Sorry, an unknown error occurred. Please contact support.'
                    });
                    return;
                }

                let wtRes = await biometricService.syncWeightWithApi(weight);
                if (!wtRes.success) {
                    messageApi.open({
                        type: 'error',
                        content: 'Sorry, an unknown error occurred. Please contact support.'
                    });
                    return;
                }

                let wtLossGoalRes = await WeightLossGoalService.createWeightLossGoal(wtLossGoal);
                if (!wtLossGoalRes.success) {
                    messageApi.open({
                        type: 'error',
                        content: 'Sorry, an unknown error occurred. Please contact support.'
                    });
                    return;
                }

                let completeInviteRes = await new CoachingProgramService().completePendingInvite();
                if (!completeInviteRes.success) {
                    messageApi.open({
                        type: 'error',
                        content: 'Sorry, an unknown error occurred. Please contact support.'
                    });
                    return;
                }

                navigate("/programSignup/3");
            }
        }
    }

    const onWeightChange = async (value: any) => {
        const re = /^(?:0|[1-9]\d+|)?(?:.?\d{0,2})?$/; //note: limits to 2 decimal place
        let valueCopy = value;

        if (value === '' || re.test(value)) {
            if (isStandardWt) {
                setTargetWeightLbs(valueCopy);
                let kg = biometricService.convertLbsToKg(parseFloat(valueCopy));
                setTargetWeightKg(kg === 0 ? '' : kg.toString());
            } else { //biometricService.weightKg case
                setTargetWeightKg(valueCopy);
                let lbs: number = biometricService.convertKgToLbs(parseFloat(valueCopy));
                setTargetWeightLbs(lbs === 0 ? '' : lbs.toString());
            }
            setTargetWeight(valueCopy);
        }
    }

    const calculateTargetCalories = (newActivityFactor:number, isBypassingWarningMsg = false, isRequestFromToggle = false) => {
        let targetCals:number;

        let bmr;
        var duration = moment.duration(moment().diff(moment(user.dob)));
        var ageInYears = parseInt(duration.asYears().toString());

        if (user.gender === biometricService.sexMale) {
            bmr = (10 * Number(weight.weightKg)) + (6.25 * user.heightCm) - (5 * Math.round(ageInYears)) + 5;
        } else { //female
            bmr = (10 * Number(weight.weightKg)) + (6.25 * user.heightCm) - (5 * Math.round(ageInYears)) - 161;
        }

        let totalCalsBurned = bmr * newActivityFactor;
        targetCals = Math.round(totalCalsBurned - 500);

        if (!isBypassingWarningMsg || !hasPreviouslySetGoals) {
            if (user.gender === biometricService.sexMale && targetCals < 1600) {
                targetCals = 1600;
            } else if (user.gender === biometricService.sexFemale && targetCals < 1100) {
                targetCals = 1100;
            }
        }

        // leaving in, may want later on
        // if (hasPreviouslySetGoals && !isBypassingWarningMsg && !isRequestFromToggle) {
        //     if (user.gender == biometricService.sexMale && targetCalories < 1600) {
        //         toggleWarningDialog();
        //         return;
        //     } else if (user.gender == biometricService.sexFemale && targetCalories < 1100) {
        //         toggleWarningDialog();
        //         return;
        //     }
        // }

        return targetCals;
    }

    const onActivityLevelChanged = (e: RadioChangeEvent) => {
        setSelectedActivityLevel(Number(e.target.value));
    };

    const onWtLbsKgToggleChange = (e: RadioChangeEvent) => {
        let standardWt = e.target.value === 'lbs';
        setIsStandardWt(standardWt);

        if (standardWt && targetWeightLbs) {
            setTargetWeight(targetWeightLbs);
        } else if (!standardWt && targetWeightKg) {
            setTargetWeight(targetWeightKg);
        }
    }

    const onSubmit = () => {
        setHasAttemptedSubmit(true);

        validateForm(true);
    }

    const isContinueBtnDisabled = ():boolean => {
        return (weightErrMsg || !selectedActivityLevel) ? true : false;
    }

    return (
        <>
            {contextHolder}
            <div className="targetWeightAndActivity" style={{ maxWidth: 300, margin: 'auto' }}>
                <Typography.Title level={4} style={{ marginBottom: 10 }}>
                    Weight
                </Typography.Title>
                <Form
                    layout="vertical"
                    onFinish={onSubmit}
                    autoComplete="off">
                    <Flex justify={'space-between'} style={{ marginBottom: -10 }}>
                        <div>Current Weight</div>
                        <div>{weightDisplay}</div>
                    </Flex>
                    <Divider />
                    <Flex justify={'space-between'} style={{ marginTop: -10 }}>
                        <Form.Item 
                            label="Target Weight">
                            <InputNumber
                                placeholder="Enter target weight"
                                style={{ width: '100%' }}
                                value={targetWeight as string}
                                onChange={onWeightChange}
                                status={weightErrMsg ? 'error' : ''} />
                            {weightErrMsg && <div style={{ color: 'red', fontSize: 12 }}>{weightErrMsg}</div>}
                        </Form.Item>
                        <Form.Item label=" ">
                            <Radio.Group value={isStandardWt ? 'lbs' : 'kg'} onChange={onWtLbsKgToggleChange} buttonStyle="solid">
                                <Radio.Button value="lbs">lbs</Radio.Button>
                                <Radio.Button value="kg">kg</Radio.Button>
                            </Radio.Group>
                        </Form.Item>
                    </Flex>
                    <Typography.Title level={4} style={{ marginBottom: 10 }}>
                        Your current activity level
                    </Typography.Title>
                    <Radio.Group onChange={onActivityLevelChanged} value={selectedActivityLevel}>
                        <Space direction="vertical">
                            <Radio value={1.2}>
                                <b>Sedentary</b>
                                <div>Little to no exercise, such as a desk job with no additional physical activity</div>
                            </Radio>
                            <Radio value={1.375}>
                                <b>Lightly Active</b>
                                <div>Light exercise 1-2 days/week</div>
                            </Radio>
                            <Radio value={1.55}>
                                <b>Moderately Active</b>
                                <div>Moderate exercise 3-5 days/week</div>
                            </Radio>
                            <Radio value={1.725}>
                                <b>Very Active</b>
                                <div>Hard exercise 6-7 days/week</div>
                            </Radio>
                            <Radio value={1.9}>
                                <b>Extremely Active</b>
                                <div>Hard daily exercise and physical job or two times a day training</div>
                            </Radio>
                        </Space>
                    </Radio.Group>
                    <Flex justify={'center'} style={{marginTop: 30}}>
                        <Form.Item>
                            <Button type="primary" htmlType="submit" disabled={isContinueBtnDisabled()}>Continue</Button>
                        </Form.Item>
                    </Flex>
                </Form>
            </div>
        </>

    )
}
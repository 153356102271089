import { Flex, Typography } from "antd";
import { useContext, useEffect, useState } from "react"
import { WeightLossGoalForApi } from "../../types/DTOs";
import appStore from '../../img/app-store.svg';
import googlePlay from '../../img/google-play-badge.png';
import { ProgramSignupContext } from "../../hooks/ContextHooks";
import { useNavigate } from "react-router-dom";

export default function ReviewWeightLossGoalTargets() {
    const { state }:any = useContext(ProgramSignupContext);
    const [hasWeightLossGoal, setHasWeightLossGoal] = useState<boolean>(true);

    const weightLossGoal:WeightLossGoalForApi = state.weightLossGoal;

    const navigate = useNavigate();

    useEffect(() => {
        if (!weightLossGoal || (!weightLossGoal.targetCals && !weightLossGoal.activityFactor)) setHasWeightLossGoal(false);//navigate("/programSignup/1");
    }, [weightLossGoal]);

    return (
        <>
            <div className="reviewWeightLossGoalTargets" style={{ maxWidth: 300, margin: 'auto' }}>
                <Typography.Title level={4} style={{ marginBottom: 10 }}>
                    Download the App!
                </Typography.Title>
                <p>Now it's time to download the <b>Macro Coach</b> app for your device and get started with the program. You will use this app to access the on demand video library, your daily food logger, and much more! We are excited about what you will accomplish!</p>
                <Flex justify={'space-between'} className="app-store-links">
                    <a href='https://apps.apple.com/app/macro-coach-weight-loss/id6447108107'><img src={appStore} alt="app store" /></a>
                    <a href='https://play.google.com/store/apps/details?id=com.lindseymichael.macrocoach'><img src={googlePlay} alt="google play" /></a>
                </Flex>
                {hasWeightLossGoal && <>
                    <Typography.Title level={4} style={{ marginBottom: 10 }}>
                        Your Daily Targets
                    </Typography.Title>
                    <p>You can quickly review your daily targets.</p>
                    <Flex justify={'space-between'} style={{marginBottom: 20, color: '#F89719'}}>
                        <b>Calories</b>
                        <b>{weightLossGoal && weightLossGoal.targetCals.toLocaleString()}</b>
                    </Flex>
                    <Flex justify={'space-between'} style={{marginBottom: 20, color: '#8db40b'}}>
                        <b>Protein</b>
                        <b>{weightLossGoal && weightLossGoal.targetProtein.toLocaleString()}</b>
                    </Flex>
                    <Flex justify={'space-between'} style={{marginBottom: 20, color: '#1e8eab'}}>
                        <b>Carbohydrates</b>
                        <b>{weightLossGoal && weightLossGoal.targetCarbs.toLocaleString()}</b>
                    </Flex>
                    <Flex justify={'space-between'} style={{color: '#EC564F'}}>
                        <b>Fats</b>
                        <b>{weightLossGoal && weightLossGoal.targetFats.toLocaleString()}</b>
                    </Flex>
                </>}
            </div>
        </>

    )
}
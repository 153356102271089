import { Badge, Button, DatePicker, Flex, Input, message, Modal, Select, Space, Table, Tag } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import moment from 'moment';
import { useContext, useEffect, useState } from "react";
import { CoachingProgramInviteUserRequestData, CoachingProgramService } from '../services/CoachingProgramService';
import UserService from '../services/UserService';
import { CoachingProgram, CoachNotification } from '../types/CoreTypes';
import { Link, useNavigate } from 'react-router-dom';
import { MessageTwoTone, CopyTwoTone, CheckCircleTwoTone } from '@ant-design/icons';
import CommonDataService from '../services/CommonDataService';
import dayjs from 'dayjs';
import NotificationService from '../services/NotificationService';
import _ from 'lodash';
import WeeklyCheckinService from '../services/WeeklyCheckinService';
import { NotificationContext } from '../hooks/ContextHooks';

export default function Users() {
    let activePrograms: Array<CoachingProgram>;
    const [userTableData, setUserTableData] = useState<Array<DataType> | undefined>(undefined);
    const [isClientInviteModalOpen, setIsClientInviteModalOpen] = useState(false);
    const [email, setEmail] = useState<string>();
    const [emailErrMsg, setEmailErrMsg] = useState<string>();
    const [firstName, setFirstName] = useState<string>();
    const [firstNameErrMsg, setFirstNameErrMsg] = useState<string>();
    const [lastName, setLastName] = useState<string>();
    const [lastNameErrMsg, setLastNameErrMsg] = useState<string>();
    const [showSuccessMsg, setShowSuccessMsg] = useState<boolean>(false);
    const [durationInMonths, setDurationInMonths] = useState<number>();
    const [durationInMonthsErrMsg, setDurationInMonthsErrMsg] = useState<string>();
    const [programPlan, setProgramPlan] = useState<string>();
    const [programPlanErrMsg, setProgramPlanErrMsg] = useState<string>();
    const [formattedStartDate, setFormattedStartDate] = useState<string>();
    //const [coachNotification, setCoachNotification] = useState<CoachNotification>({ unreadUserCheckins: [] });

    const [messageApi, contextHolder] = message.useMessage();

    const navigate = useNavigate();

    const { state, update }:any = useContext(NotificationContext);

    const unreadUserCheckins = state.unreadUserCheckins;

    interface DataType {
        key: string;
        name: string;
        startDate: string;
        endDate: string;
        email: string;
        tags: string[];
        uid: string,
        inviteId?: string
    }

    useEffect(() => {
        window.scrollTo(0, 0);

        //loadNotifications();

        loadAllProgramUsers();

        let programStartDateAsString = moment().startOf('isoWeek').add(1, 'week').format('MM/DD/YYYY');
        
        setFormattedStartDate(programStartDateAsString);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loadAllProgramUsers = async () => {
        activePrograms = await new CoachingProgramService().getAllProgramUsers();

        const data: Array<DataType> = [];

        activePrograms.forEach((program: CoachingProgram, index) => {
            let tag2 = program.isPaidInFull ? 'Paid In Full' : 'Monthly Installments';
            let item: DataType = {
                key: (index + 1).toString(),
                name: !program.inviteId ? UserService.getFullName(program.firstName, program.lastName) : `${UserService.getFullName(program.firstName, program.lastName)}, Invite Sent`,
                startDate: moment(program.startDateTime).format('MMM DD, YYYY'),
                endDate: moment(program.endDateTime).format('MMM DD, YYYY'),
                email: program.email,
                tags: [program.status, program.plan ? program.plan : program.type, tag2],
                uid: program.uid
            }
            if (program.inviteId) item['inviteId'] = program.inviteId;
            data.push(item);
        })

        setUserTableData(data);
    }


    const onStartDateChanged = (date: any, dateString: string) => {
        setFormattedStartDate(dateString);
    };

    const validateEmail = (email: string) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    const sendInvite = async () => {
        if (!firstName || firstName.length < 2) {
            setFirstNameErrMsg('First Name is required')
        } else if (!lastName || lastName.length < 2) {
            setLastNameErrMsg('Last Name is required')
        } else if (!validateEmail(email as string)) {
            setEmailErrMsg("Invalid email address");
        } else if (!durationInMonths) {
            setDurationInMonthsErrMsg("Select a duration in months");
        } else if (!programPlan) {
            setProgramPlanErrMsg("select a plan");
        } else {
            setEmailErrMsg('');

            const pricePerMonth: number = 199.00;
            let requestData: CoachingProgramInviteUserRequestData = {
                plan: programPlan as string,
                type: programPlan?.toLowerCase() === 'platinum' ? '1:1' : 'group',
                firstName: firstName as string,
                lastName: lastName as string,
                email: email as string,
                numMonths: durationInMonths,
                coachUid: CommonDataService.getCurrentUser().uid,
                isPaidInFull: false,
                pricePerMonth,
                programStartDate: moment(formattedStartDate, 'MM/DD/YYYY').format('YYYY-MM-DD'),
                priceInFull: pricePerMonth * durationInMonths
            };
            let res = await new CoachingProgramService().inviteUser(requestData);

            if (res) {
                setShowSuccessMsg(true);

                messageApi.open({
                    type: 'success',
                    content: 'The invitation has been sent!',
                });

                loadAllProgramUsers();
            } else {
                messageApi.open({
                    type: 'error',
                    content: 'Sorry, we were unable to invite the user at this time.',
                });
            }
        }
    }

    const resendInvite = async (email: string, inviteId: string) => {
        let res = await new CoachingProgramService().resendUserInvite(email, inviteId);

        if (res) {
            messageApi.open({
                type: 'success',
                content: 'The invitation has been resent!',
            });
        } else {
            messageApi.open({
                type: 'error',
                content: 'Sorry, we were unable to resend the email at this time.',
            });
        }
    }

    const getUnreadUserCheckinMsgsByUserUid = (uid: string) => {
        let found = _.find(unreadUserCheckins, { uid });

        return found?.numUnreadByCoach ?? 0;
    }

    const resetModal = () => {
        setShowSuccessMsg(false);
        setIsClientInviteModalOpen(false);
        setDurationInMonths(undefined);
        setDurationInMonthsErrMsg(undefined);
        setProgramPlan(undefined);
        setProgramPlanErrMsg(undefined);
        setEmail(undefined);
    }

    const columns: ColumnsType<DataType> = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            render: (text) => <a>{text}</a>,
        },
        {
            title: 'Start Date',
            dataIndex: 'startDate',
            key: 'startDate',
            responsive: ['md'],
        },
        {
            title: 'End Date',
            dataIndex: 'endDate',
            key: 'endDate',
            responsive: ['md'],
        },
        {
            title: 'Email Address',
            dataIndex: 'email',
            key: 'email',
            responsive: ['md'],
        },
        {
            title: 'Program Type',
            key: 'tags',
            dataIndex: 'tags',
            responsive: ['md'],
            render: (_, { tags }) => (
                <>
                    {tags.map((tag) => {
                        let color = tag.length > 5 ? 'geekblue' : 'green';
                        if (tag === 'active') {
                            color = 'green';
                        } else if (tag === 'completed'){
                            color = 'gold';
                        } else {
                            color = 'geekblue';
                        }
                        return (
                            <Tag color={color} key={tag}>
                                {tag.toUpperCase()}
                            </Tag>
                        );
                    })}
                </>
            ),
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    {!record.inviteId && <>
                        <Link to={`/messages`} state={{ uid: record.uid, name: record.name }}><MessageTwoTone style={{ fontSize: 24 }} /></Link>
                        <Link to={"/userLog"} state={{ user: record }}><CopyTwoTone style={{ fontSize: 24 }} /></Link>
                        <Badge count={getUnreadUserCheckinMsgsByUserUid(record.uid)}>
                            <a onClick={async() => {
                                    await WeeklyCheckinService.markUserCheckinAsReadByCoach(record.uid);
                                    await update({...{ unreadUserCheckins: [] }});
                                    navigate('/weeklyCheckins', {state: { user: record }});
                                }}><CheckCircleTwoTone style={{ fontSize: 24 }} /></a>
                        </Badge>
                        
                    </>}
                    {record.inviteId && <Button type='primary' onClick={() => { resendInvite(record.email, record.inviteId as string); }}>Resend Invite</Button>}
                </Space>
            ),
        },
    ];

    return (
        <>
            {contextHolder}
            <div className="users">
                <Flex justify={'space-between'}>
                    <h1>Users</h1>
                    <Button type='default' style={{ marginTop: 22 }} onClick={() => setIsClientInviteModalOpen(true)}>
                        Invite New Client
                    </Button>
                </Flex>
                <Table dataSource={userTableData} columns={columns} bordered />
                <Modal
                    title="Send Invite Email"
                    open={isClientInviteModalOpen}
                    onOk={!showSuccessMsg ? sendInvite : resetModal}
                    onCancel={resetModal}
                    cancelButtonProps={{ style: showSuccessMsg ? { display: 'none' } : { display: 'inline-block' } }}
                    okText={!showSuccessMsg ? 'Send Invite' : 'Done'}>
                    {!showSuccessMsg && <>
                        {/* <p>Please provide the email address of the client you would like to invite. Note: Double check that the email is correct. We will send an invite to the email you provide, which will unlock the program.</p> */}
                        <Flex justify={'space-between'} style={{ marginTop: 10, marginBottom: 20 }}>
                            <div style={{ paddingRight: 10 }}>
                                <b>First Name:</b>
                                <Input
                                    onChange={(e) => {
                                        setFirstName(e.target.value);
                                        if (e.target.value) setFirstNameErrMsg(undefined);
                                    }}
                                    status={firstNameErrMsg ? 'error' : ''}
                                    required
                                    placeholder='First Name' />
                                {firstNameErrMsg && <div style={{ color: 'red', fontSize: 12 }}>{firstNameErrMsg}</div>}
                            </div>
                            <div style={{ paddingLeft: 10 }}>
                                <b>Last Name:</b>
                                <Input
                                    onChange={(e) => {
                                        setLastName(e.target.value);
                                        if (e.target.value) setLastNameErrMsg(undefined);
                                    }}
                                    status={lastNameErrMsg ? 'error' : ''}
                                    required
                                    placeholder='Last Name' />
                                {lastNameErrMsg && <div style={{ color: 'red', fontSize: 12 }}>{lastNameErrMsg}</div>}
                            </div>
                        </Flex>


                        <b>Email Address:</b>
                        <Input
                            onChange={(e) => {
                                setEmail(e.target.value);
                                if (e.target.value) setEmailErrMsg(undefined);
                            }}
                            type="email"
                            status={emailErrMsg ? 'error' : ''}
                            required
                            placeholder='Enter the email address of the recipient you would like to invite' />
                        {emailErrMsg && <div style={{ color: 'red', fontSize: 12 }}>{emailErrMsg}</div>}
                        <div style={{ marginTop: 20 }}>
                            <b>Program Plan: </b>
                            <Select
                                placeholder="Select the plan"
                                optionFilterProp="children"
                                onChange={(value: string) => {
                                    setProgramPlan(value);
                                    if (value) setProgramPlanErrMsg(undefined);
                                }}
                                value={programPlan}
                                options={[
                                    { value: 'silver', label: 'Silver' },
                                    { value: 'gold', label: 'Gold' }
                                ]}
                                status={programPlanErrMsg ? 'error' : ''} />
                            {programPlanErrMsg && <div style={{ color: 'red', fontSize: 12 }}>{programPlanErrMsg}</div>}
                        </div>
                        <div style={{ marginTop: 20 }}>
                            <b>Program duration: </b>
                            <Select
                                placeholder="Duration in Months"
                                optionFilterProp="children"
                                onChange={(value: number) => {
                                    setDurationInMonths(value);
                                    if (value) setDurationInMonthsErrMsg(undefined);
                                }}
                                value={durationInMonths}
                                options={[
                                    { value: 1, label: '1 Month' },
                                    { value: 2, label: '2 Months' },
                                    { value: 3, label: '3 Months' },
                                    { value: 4, label: '4 Months' },
                                    { value: 5, label: '5 Months' },
                                    { value: 6, label: '6 Months' },
                                    { value: 7, label: '7 Months' },
                                    { value: 8, label: '8 Months' },
                                    { value: 9, label: '9 Months' },
                                    { value: 10, label: '10 Months' },
                                    { value: 11, label: '11 Months' },
                                    { value: 12, label: '12 Months' }
                                ]}
                                status={durationInMonthsErrMsg ? 'error' : ''} />
                            {durationInMonthsErrMsg && <div style={{ color: 'red', fontSize: 12 }}>{durationInMonthsErrMsg}</div>}
                        </div>
                        <div style={{ marginTop: 20 }}>
                            <b>Program start date: </b>
                            <DatePicker 
                                onChange={onStartDateChanged}
                                defaultValue={dayjs(formattedStartDate, 'MM/DD/YYYY')} format={'MM/DD/YYYY'} />
                        </div>
                    </>}
                    {showSuccessMsg && <>
                        <p>An invitation has been sent to {email}</p>
                    </>}
                </Modal>
            </div>
        </>
    )
}
import { DeleteOutlined } from "@ant-design/icons";
import { Button, Card, Modal } from "antd";
import Paragraph from "antd/lib/typography/Paragraph";
import _ from "lodash";
import moment, { Moment } from "moment";
import { useContext, useEffect, useState } from "react";
import { UserNotesContext } from "../../hooks/ContextHooks";
import NoteService from "../../services/NoteService";

export type ClientNoteProps = {
    content: string,
    dateTime: Moment,
    uid?: string,
    title?: string,
    id?: string
};

export type Props = {
    note: ClientNoteProps
}

export default function ClientNote({ note }: Props) {
    const [editableContent, setEditableContent] = useState<string>();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const { state, update }: any = useContext(UserNotesContext);

    // const form = [
    //     {
    //         id: 1,
    //         title: `What's at least one WIN you've had in your health, well-being, or life in general, since we last spoke? Remember non-scale and habit-based wins are what we are going towards for long-term success!`,
    //         maxLength: 500
    //     },
    //     {
    //         id: 2,
    //         title: `What were your main goals/action steps since we last spoke?`,
    //         maxLength: 500
    //     },
    //     {
    //         id: 3,
    //         title: `How is your progress towards these goals or action steps? How do you feel about the process changes you've made overall?`,
    //         maxLength: 500
    //     },
    //     {
    //         id: 4,
    //         title: `If the outcome was not what you'd hoped for, why do you think that is? What barriers got in the way of your success?`,
    //         maxLength: 500
    //     },
    //     {
    //         id: 5,
    //         title: `What is ONE habit you can improve on moving forward? Or maybe one habit to continue to work on until it's a discipline?`,
    //         maxLength: 500
    //     },
    //     {
    //         id: 6,
    //         title: `What are the main things you need from your dietitian right now? (Coaching, education, support, clarification, accountability, etc)`,
    //         maxLength: 500
    //     },
    //     {
    //         id: 7,
    //         title: `Changes to body measurements (if applicable) Enter n/a if you have not taken body measurements or it's not measurement time. Once every 30 days or so is a good timeframe to re-do measurements.`,
    //         maxLength: 500
    //     },
    //     {
    //         id: 8,
    //         title: `Changes to labs? (ex: reduced cholesterol, improved blood pressure, etc)`,
    //         maxLength: 500
    //     },
    //     {
    //         id: 9,
    //         title: `On a scale of 1-10, please rate the following:`,
    //         subQuestions: [
    //             {
    //                 id: 10,
    //                 title: `Sleep (1=Very interrupted or less than a few hours sleep, 10=Sleeping great! 7-9 hours quality sleep)`,
    //                 maxLength: 2,
    //                 type: 'integer'
    //             },
    //             {
    //                 id: 11,
    //                 title: `Energy (1=Exhausted/Lethargic, 10=Feeling Amazing!)`,
    //                 maxLength: 2,
    //                 type: 'integer'
    //             },
    //             {
    //                 id: 12,
    //                 title: `Stress (1=Not stressed, 10=Extremely stressed)`,
    //                 maxLength: 2,
    //                 type: 'integer'
    //             },
    //             {
    //                 id: 13,
    //                 title: `Mood (1=Very depressed, 10=Very happy)`,
    //                 maxLength: 2,
    //                 type: 'integer'
    //             },
    //             {
    //                 id: 14,
    //                 title: `Between-meal hunger (1=Well managed/controlled hunger, 10=Ravenous after eating or between meals)`,
    //                 maxLength: 2,
    //                 type: 'integer'
    //             }
    //         ]
    //     },
    //     {
    //         id: 15,
    //         title: `Is there anything else you want your dietitian to know or any questions not addressed on this form?`,
    //         maxLength: 750
    //     }
    // ];

    useEffect(() => {
        setEditableContent(note.content);
    }, [note]);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = async () => {
        setIsModalOpen(false);
        let res = await NoteService.deleteUserNote(note.id);
        if (res.success) {
            let notesCopy = [...state.notes];
            _.remove(notesCopy, { id: note.id });
            update({ notes: notesCopy });
        }
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const onContentChanged = async (value: string) => {
        await NoteService.updateUserNote({ title: note.title, content: value, dateTime: note.dateTime, id: note.id, uid: note.uid });
        setEditableContent(value);
    }

    const deleteNote = () => {
        showModal();
    }

    return (
        <div className="clientNote">
            <Card title={note.title ? note.title : ''} extra={<span>{moment(note.dateTime).local().format('MM/DD/YYYY hh:mm a')} <Button style={{ marginLeft: 10 }} onClick={deleteNote} shape="circle" icon={<DeleteOutlined />} /> </span>}>
                <Paragraph editable={{ triggerType: ['text', 'icon'], onChange: onContentChanged }}>{editableContent}</Paragraph>
            </Card>
            <Modal title="Delete Note?" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <p>If you continue, {note.title ? `the note: ${note.title}` : 'this note'} will be deleted</p>
            </Modal>
        </div>
    )
};
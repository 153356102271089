import { useEffect } from "react"
import { Link } from "react-router-dom"

export default function Support() {

    useEffect(() => {
        window.scrollTo(0, 0);

        
    }, []);

    return(
        <>
            <div className="support">
                
                <div className="close"><Link to={"/"}>Close</Link></div>
                <h1>Support</h1>

                <h2>For general support questions you can reach us at support@macrocoach.io</h2>

                <h3>Forgot password?</h3>
                <p>If you have forgotten your password, you can reset it within the app. To do so, open the app and tap on <b>Log In</b> which is found on the initial <b>Sign Up</b> screen. Once on the <b>Log In</b> screen, tap the option <b>Forgot Password?</b>. You will be prompted to enter your email address. This email address must be the email address you used when creating your account. We will send a temporary password to the registered email address. You will enter this temporary password to verify access to your account. Upon entering this temporary password, you will be able to reset your password. After resetting your password, you will be able to login to the app.</p>

                <h3>Reset password?</h3>
                <p>There is an option to reset your password within the <b>Settings Tab</b>. For security reasons, in order to reset your password, you must first confirm your current password. Once you confirm your new password, you can supply your new password and tap on the button below <b>Reset Password</b>.</p>

                <h3>Unable to find a certain food when searching foods or drinks to log?</h3>
                <p>At times, you may be unable to find the exact food you are looking for. When at home, most of the time you will be able to have nutrition information for the item you are logging. You can search similar brands that have similar nutrient information and log these brands instead. If you are at a restaurant that is not a large chain, it's going to be more difficult to find that exact item. Pay attention to the ingredients and eyeball the portion sizes. You can look for each of these portions individually or find the same dish at a larger chain and log it instead.</p>

                <h3>Unable to create a recipe?</h3>
                <p>We are currently working on providing the ability to search and create recipes. In the meantime, if you would like to create a recipe, we do have the option to create bookmarks. You can go to log a meal (e.g. breakfast), search and log several meal entries that would be your recipe items. Once you are done adding items, you can tap on the button at the bottom of the screen titled <b>Bookmark Meal</b>. You can then provide a bookmark name for quick lookup at a later time. When bookmarking a meal, you can enter several meal entries of varying portion sizes. This can work similar to a recipe while we continue to build this feature.</p>

                <h3>Delete you account?</h3>
                <p>You can request to delete your account by navigating to the Settings tab and scrolling all the way to the bottom, where you will find a button titled <b>Delete Account</b>. You can tap on this option and then confirm. Once you have done this, your account will be flagged for deletion. This request can take up to 30 days to process.</p>
                {/* <Helmet>
                    <script
                    type="text/javascript"
                    src={ SCRIPT_SRC }
                    />
                </Helmet>

                <div
                    data-id={ uuid }
                    data-type="iframe"
                    name="termly-embed"
                /> */}
            </div>
        </>

    )
}
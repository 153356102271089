export default function ErrorPage() {
    //const error:any = useRouteError();
    //console.error(error);

    return (
        <>
            <div id="error-page">
                <div className="content-wrap">
                    <h1>Oops!</h1>
                    <p>Sorry, page not found.</p>
                    <p>
                    {/* <i>{error.statusText || error.message}</i> */}
                    </p>
                </div>
            </div>
        </>
    );
}

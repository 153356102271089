// import axios from "axios";
// import { getAuth } from "firebase/auth";
// import Constants from "./Constants";

import axios from "axios";
import { getAuth } from "firebase/auth";
import { SimpleResponse, UserForApi, WeightForApi } from "../types/DTOs";
import Constants from "./Constants";

type HeightFtIn = {
    ft: number;
    inches: number;
}

type ValidationResult = {
    isValid: boolean; 
    error: string;
}

interface IBiometricService {
    weightPounds: number; //pounds db value
    weightKg:number; //kg db value
    heightFt:number; //ft/in db value
    heightCm: number; //cm db value
    sexFemale:string;
    sexMale:string;
    minWeightLbs: number;
    maxWeightLbs: number;
    minWeightKg: number;
    maxWeightKg: number;
    convertLbsToKg: (lbs:number) => number;
    convertKgToLbs: (kg:number) => number;
    convertHtFtToInches: (ft:number, inches:number) => number;
    convertInchesToHtFt: (inches:number) => HeightFtIn;
    convertInchesToCm: (inches:number) => number;
    convertCmToInches: (cm:number) => number;
    validateWeight: (weightLbs:string, weightKg:string, weightMetric:number) => ValidationResult;
    validateHeightCm: (htCm:string) => ValidationResult;
    syncUserWithApi: (user:UserForApi) => Promise<SimpleResponse>;
    syncWeightWithApi: (weight:WeightForApi) => Promise<SimpleResponse>;
}

class BiometricService implements IBiometricService {
    public readonly weightPounds = 0; //pounds db value
    public readonly weightKg = 1; //kg db value
    public readonly heightFt = 0; //ft/in db value
    public readonly heightCm = 1; //cm db value
    public readonly sexFemale = 'F';
    public readonly sexMale = 'M';
    public readonly minWeightLbs = 60;
    public readonly maxWeightLbs = 1000;
    public readonly minWeightKg = 27.22;
    public readonly maxWeightKg = 453.59;

    convertLbsToKg = (lbs:number):number => {
        if (!lbs) return 0;
    
        return Math.round(lbs * .45359237 * 100) / 100; //round 2 decimals
    }
    
    convertKgToLbs = (kg:number):number => {
        if (!kg) return 0;
    
        return Math.round(kg * 2.20462 * 100) / 100; //round 2 decimals
    }
    
    convertHtFtToInches = (ft:number, inches:number):number => {
        if (!ft) return 0;
    
        return (ft*12) + inches;
    }
    
    convertInchesToHtFt = (inches:number):HeightFtIn => {
        if (!inches) return { ft: 0, inches: 0 };
        let ft = Math.floor(inches/12);
        let inc = inches % 12;
    
        return { ft, inches: inc };
    }
    
    convertInchesToCm = (inches:number):number => {
        if (!inches) return 0;
    
        return Math.round(inches*2.54);
    }
    
    convertCmToInches = (cm:number):number => {
        if (!cm) return 0;
    
        return (Math.round(cm / 2.54)); //need to round to a whole inch
    }

    validateHeightCm = (htCm:string) => {
        let isValid = true;
        let htError = '';
    
        let htCmAsFloat = parseFloat(htCm);

        if (!htCm || htCm.endsWith('.') || !htCmAsFloat || htCmAsFloat > 241 || htCmAsFloat < 91) {
            htError = "Invalid height";
            isValid = false;
        }

        return { isValid, error: htError };
    }
    
    validateWeight = (weightLbs:string, weightKg:string, weightMetric:number): ValidationResult => {
        let isValid = true;
        let wtError = '';
    
        if (!weightLbs || weightLbs === '0' || weightLbs.endsWith('.') || weightKg.endsWith('.') || !parseFloat(weightLbs)) {
            wtError = "Invalid weight";
            isValid = false;
        }
    
        if ((weightMetric === this.weightPounds && parseFloat(weightLbs) < this.minWeightLbs) || (weightMetric === this.weightKg && parseFloat(weightKg) < this.minWeightKg)) {
            isValid = false;
            wtError = "Weight is too low";
        }
    
        if ((weightMetric === this.weightPounds && parseFloat(weightLbs) > this.maxWeightLbs) || (weightMetric === this.weightKg && parseFloat(weightKg) > this.maxWeightKg)) {
            isValid = false;
            wtError = "Weight is too high";
        }
        
        return { isValid, error: wtError };
    }

    syncUserWithApi = async (user:UserForApi) => {
        const auth = getAuth();
        let idToken = await auth.currentUser?.getIdToken(true);
    
        try {
            if (!idToken) throw new Error("invalid token");
            let res = await axios({
                url: `${Constants.apiBaseUrl}/user`,
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${idToken}`
                },
                data: user
            });
            return { success: true };
            //return { syncDate: res.data.syncDate, key: res.data.key };
        } catch (err) {
            //return { syncDate: null, key: null };
            return { success: false };
        }
    }

    syncWeightWithApi = async (weight:WeightForApi) => { //note: weight can be of type WeightForApi or Array<WeightForApi>. This works in both cases
        const auth = getAuth();
        let idToken = await auth.currentUser?.getIdToken(true);
    
        try {
            if (!idToken) throw new Error("invalid token");
            let res = await axios({
                url: `${Constants.apiBaseUrl}/weight`,
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${idToken}`
                },
                data: weight
            });
    
            return { success: true };
        } catch (err) {
            return { success: false };
        }
    }

}

const biometricService = new BiometricService() as IBiometricService;
export { biometricService, type HeightFtIn, type ValidationResult }
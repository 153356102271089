import { auth } from '../firebase';
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import { signOut } from 'firebase/auth';
//import { useAuthentication } from './hooks/useAuthentication';
import { useAuthState } from 'react-firebase-hooks/auth';
import CommonDataService from '../services/CommonDataService';

export default function Footer() {
    const [user, loading] = useAuthState(auth);
    
    const Year = new Date().getFullYear();

    if (loading) return null; 

    const logout = () => {
        CommonDataService.removeCurrentUser();
        signOut(auth);
    }

    const RenderLoginLogout = () => {
        //const { user } = useAuthentication();
        if (user) return <Button onClick={logout} type="link">Logout</Button>;
        else return <Link to={"/login"}>Login</Link>;
   }

    return(
        <footer>
            <div className="footer-wrap">
                <p className="copy">
                    Copyright {Year} Lindsey Michael MS RDN LLC | 
                    <Link to={"/support"}>Support</Link> | 
                    <Link to={"/terms-service"}>Terms of Use</Link> | 
                    <Link to={"/privacy-policy"}>Privacy Policy</Link> |
                    {/* <Button onClick={logout} type="link">Logout</Button> */}
                    <RenderLoginLogout />
                </p>

                {/* <div className="social-links">
                    <a href="#">
                        <img src={facebook} alt="facebook" />
                    </a>
                    <a href="#">
                        <img src={instagram} alt="instagram" />
                    </a>
                </div> */}
            </div>
        </footer>
    )
}
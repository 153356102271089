import axios from "axios";
import { getAuth } from "firebase/auth";
import { ClientNoteProps } from "../components/Notes/ClientNote";
import Constants from "./Constants";


interface INotificationService {
    getCoachNotifications: () => any;
}

class NotificationService implements INotificationService {
    getCoachNotifications = async() => {
        const auth = getAuth();
        let idToken = await auth.currentUser?.getIdToken(true);
    
        try {
            if (!idToken) throw new Error("invalid token");

            let res = await axios({
                url: `${Constants.apiBaseUrl}/notification/coach`,
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${idToken}`
                }
            });
            return { success: true, notifications: res.data };
        } catch (err) {
            //could alert there was error or that they are in offline mode
            return { success: false };
        }
    }
}

export default new NotificationService() as INotificationService;
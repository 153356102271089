// Import the functions you need from the SDKs you need
import { initializeApp, FirebaseApp, getApps, getApp } from "firebase/app";
import { Auth, getAuth, initializeAuth, browserLocalPersistence } from "firebase/auth";
import { Firestore, initializeFirestore } from 'firebase/firestore';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration

const firebaseConfig = {
  apiKey: "AIzaSyCO0Pv6joBFER1wAUyM8zXMvq10h1jDx2A",
  authDomain: "my-health-coach-6025e.firebaseapp.com",
  projectId: "my-health-coach-6025e",
  storageBucket: "my-health-coach-6025e.appspot.com",
  messagingSenderId: "265914594809",
  appId: "1:265914594809:web:e440bb3f4fafd02c71f928"
};

// Initialize Firebase
/*const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);
export default app;*/

//note: had to do the below b/c firebase plugin was trying to use an outdated version of AsyncStorage. If it werent or this, I could use the line above and export default app;
let firebaseApp: FirebaseApp;
let fireAuth: Auth;
if (getApps().length < 1) {
    firebaseApp = initializeApp(firebaseConfig);
    fireAuth = initializeAuth(firebaseApp, {
        persistence: browserLocalPersistence
    });
} else {
    firebaseApp = getApp();
    fireAuth = getAuth();
}

let db: Firestore = initializeFirestore(firebaseApp, {experimentalForceLongPolling: true});
//end note:

let auth = fireAuth;

//export default firebaseApp;
export { db, auth };
import { NavigationItems } from "../common/NavigationItems"
import { SubNavigationItems } from "../common/NavigationItems"
import { NavLink } from "react-router-dom";
import type { MenuProps } from 'antd';
import { Menu } from "antd";
import { BellTwoTone } from "@ant-design/icons";

export default function NavigationBar() {
    const mainNavItems:MenuProps['items'] = NavigationItems.map((item, key) => {
        return {
                label: <NavLink
                    className={({ isActive }) => isActive ? "active" : "" }
                    to={item.url}
                >{item.label}</NavLink>,
                key: key
        }
    });
    const subNavItems:MenuProps['items'] = SubNavigationItems.map((item, key) => {
        return {
                label: <NavLink
                    className={({ isActive }) => isActive ? "active" : "" }
                    to={item.url}
                >{item.label}</NavLink>,
                key: key
        }
    });

    return (
        <nav>
            <Menu className="main-nav" selectedKeys={[]} mode="horizontal" items={mainNavItems} style={{ minWidth: 0, flex: "auto" }} />
            <Menu className="sub-nav" selectedKeys={[]} mode="horizontal" items={subNavItems} />
            <div style={{marginLeft: 16, marginRight: 16}}><BellTwoTone style={{fontSize:22}} /></div>
        </nav>
    )
}
import { Button, Form, Input } from "antd";
import TextArea from "antd/es/input/TextArea";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { UserNotesContext } from "../../hooks/ContextHooks";
import NoteService from "../../services/NoteService";
import { ClientNoteProps } from "./ClientNote";

export default function AddNote() {

    const location = useLocation();
    const { user } = location.state;

    const { state, update }:any = useContext(UserNotesContext);

    useEffect(() => {

    }, []);

    const onFinish = async (values: FieldType) => {
        let clientNote:ClientNoteProps ={
            content: values.content,
            title: values.title,
            dateTime: moment()
        }

        clientNote.uid = user.uid;
        let response = await NoteService.createUserNote(clientNote);

        if (response.success && response.note) {
            update({ notes: [response.note, ...state.notes as Array<ClientNoteProps>], isAddingNote: false });
        }

        values.content = '';
        values.title = '';
    };

    const onFinishFailed = (errorInfo: any) => {
        console.error('Failed:', errorInfo);
    };

    type FieldType = {
        title?: string;
        content: string;
    };

    return (
        <div className="addNote">
            <Form
                name="basic"
                labelCol={{ span: 2 }}
                wrapperCol={{ span: 16 }}
                style={{ maxWidth: 1200, borderWidth:1, margin: 'auto' }}
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Form.Item<FieldType>
                    label="Title"
                    name="title"
                >
                    <Input />
                </Form.Item>

                <Form.Item<FieldType>
                    label="Note"
                    name="content"
                    rules={[{ required: true, message: 'Please enter a note' }]}
                >
                    <TextArea rows={8} />
                </Form.Item>

                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        </div>
    )
};
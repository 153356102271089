import axios from "axios";
import { getAuth } from "firebase/auth";
import { SimpleResponse, WeightLossGoalForApi } from "../types/DTOs";
import { WeightLossGoal } from "../types/GoalTypes";
import Constants from "./Constants";

type WeightLossGoalResponse = {
    success: boolean,
    weightLossGoals?: Array<WeightLossGoal>
}

interface IWeeklyCheckinService {
    getUserCheckins: (uid: string) => Promise<any>;
    getWeeklyCheckins: (uid: string) => Promise<WeightLossGoalResponse>;
    getWeeklyCheckinTemplate: (uid:string) => Promise<any>;
    markUserCheckinAsReadByCoach: (uid: string) => Promise<any>;
    saveWeeklyCheckinResponse: (coachingProgramId:string | number, coachResponse: any, week: any) => Promise<any>;
}

class WeeklyCheckinService implements IWeeklyCheckinService {
    getUserCheckins = async (uid: string) => {
        if (!uid) return false;

        const auth = getAuth();
        let idToken = await auth.currentUser?.getIdToken(true);

        try {
            if (!idToken) throw new Error("invalid token");
            let res = await axios({
                url: `${Constants.apiBaseUrl}/weeklyCheckin/userCheckins/${uid}`,
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${idToken}`
                }
            });

            let dataToReturn = res.data;

            return dataToReturn;
        } catch (err) {
            return false;
        }
    }

    getWeeklyCheckins = async (uid: string) => {
        if (!uid) {
            console.error('uid is a required param');
            return { success: false };
        }

        const auth = getAuth();
        let idToken = await auth.currentUser?.getIdToken(true);

        try {
            if (!idToken) throw new Error("invalid token");

            let res = await axios({
                url: `${Constants.apiBaseUrl}/weeklyCheckin/${uid}`,
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${idToken}`
                }
            });
            return { success: true, weightLossGoals: res.data };
        } catch (err) {
            //could alert there was error or that they are in offline mode
            return { success: false };
        }
    }

    getWeeklyCheckinTemplate = async (uid:string) => {    
        const auth = getAuth();
        let idToken = await auth.currentUser?.getIdToken(true);

        try {
            if (!idToken) throw new Error("invalid token");
            let res = await axios({
                url: `${Constants.apiBaseUrl}/weeklyCheckin/template/${uid}`,
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${idToken}`
                }
            });

            let dataToReturn = res.data;

            return dataToReturn;
        } catch (err) {
            return false;
        }
    };

    markUserCheckinAsReadByCoach = async (uid: string) => {
        const auth = getAuth();
        let idToken = await auth.currentUser?.getIdToken(true);
    
        try {
            if (!idToken) throw new Error("invalid token");
            let res = await axios({
                url: `${Constants.apiBaseUrl}/weeklyCheckin/markFormResponseAsReadByCoach`,
                method: 'PATCH',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${idToken}`
                },
                data: { uid }
            });
            return { success: true  };
        } catch (err) {
            return { success: false };
        }
    }

    saveWeeklyCheckinResponse = async (programId:string | number, coachResponse: any, week: number) => {
        if (!programId || !coachResponse) {
            console.error('programId and coachResponse are required');
            return false;
        }

        const auth = getAuth();
        let idToken = await auth.currentUser?.getIdToken(true);

        try {
            if (!idToken) throw new Error("invalid token");
            let res = await axios({
                url: `${Constants.apiBaseUrl}/weeklyCheckin/coachResponse`,
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${idToken}`
                },
                data: {
                    programId,
                    coachResponse,
                    week
                }
            });
            return true;
        } catch (err) {
            return false;
        }
    }
}

export default new WeeklyCheckinService() as IWeeklyCheckinService
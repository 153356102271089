import axios from "axios";
import { getAuth } from "firebase/auth";
import Constants from "./Constants";


interface IWeightService {
    getWeightsFromApi: (uid: string, startDate: string, endDate: string) => any;
}

class WeightService implements IWeightService {
    getWeightsFromApi = async(uid: string, startDate: string, endDate: string) => {
        const auth = getAuth();
        let idToken = await auth.currentUser?.getIdToken(true);
    
        try {
            if (!idToken) throw new Error("invalid token");

            let res = await axios({
                //url: `${Constants.apiBaseUrl}/user
                url: `${Constants.apiBaseUrl}/weight/startDate/${startDate}/endDate/${endDate}?uid=${uid}`,
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${idToken}`
                }
            });
            return { success: true, weights: res.data };
        } catch (err) {
            //could alert there was error or that they are in offline mode
            return { success: false };
        }
    }
}

export default new WeightService() as IWeightService
import { useContext, useEffect } from "react";
import { UserNotesContext } from "../../hooks/ContextHooks";
import ClientNote, { ClientNoteProps } from "./ClientNote";

export default function ClientNotes () {
    const { state }:any = useContext(UserNotesContext);

    const notes = state.notes;

    useEffect(() => {

    }, []);

    return (
        <div className="clientNotes">
            {
                notes?.map((note: ClientNoteProps, index: number) => (
                    <div key={index} style={{marginBottom: 20}}>
                        <ClientNote note={note} />
                    </div>
                ))
            }
        </div>
    )
};